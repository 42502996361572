import { useNotifications } from '@schooly/components/notifications';
import { WithRecurringConfirmDialog } from '@schooly/components/recurring';
import { Loading, RotateIcon } from '@schooly/style';
import { WithSelectIds } from '@schooly/utils/bulk-actions';
import { usePortraitViewNotification } from '@schooly/utils/mobile';
import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { IS_DEMO } from '../config';
import { WithApplication } from '../context/applications/WithApplication';
import { WithAttendanceRegister } from '../context/attendance/WithAttendanceRegister';
import { WithCompany } from '../context/companies/WithCompany';
import { WithEvent } from '../context/events/WithEvent';
import { WithGroup } from '../context/groups/WithGroup';
import { WithGroupAttendanceStatistics } from '../context/groups/WithGroupAttendanceStatistics';
import { WithMessageWrapper } from '../context/messages/WithMessageWrapper';
import { WithProduct } from '../context/product/WithProduct';
import { WithProfileWrapper } from '../context/profile/WithProfileWrapper';
import { WithReport } from '../context/report/WithReport';
import { useRouter } from '../context/router/useRouter';
import { WithSignUp } from '../context/signUps/WithSignUp';
import { WithUserRolesForSchool } from '../context/userRoles/WithUserRolesForSchool';
import useAppLinkRedirect from '../hooks/useAppLinkRedirect';
import { useSchool } from '../hooks/useSchool';
import { useInitiateUserCountsPolling } from '../hooks/useUserCounts';
import { ApplicationAccessLayer } from '../pages/Applications/ApplicationAccessLayer';
import { ApplicationAddStudentsModal } from '../pages/Applications/ApplicationAddStudents/ApplicationAddStudentsModal';
import { ApplicationCreateGeneralInfoModal } from '../pages/Applications/ApplicationCreateModal/ApplicationCreateGeneralInfoModal';
import { ApplicationCreateModal } from '../pages/Applications/ApplicationCreateModal/ApplicationCreateModal';
import { ApplicationUpdateGeneralInfoModal } from '../pages/Applications/ApplicationCreateModal/ApplicationUpdateGeneralInfoModal';
import { ApplicationUpdateModal } from '../pages/Applications/ApplicationCreateModal/ApplicationUpdateModal';
import { ApplicationCreatePreviewModal } from '../pages/Applications/ApplicationPreview/ApplicationCreatePreviewModal';
import { ApplicationPreviewModal } from '../pages/Applications/ApplicationPreview/ApplicationPreviewModal';
import { ApplicationUpdatePreviewModal } from '../pages/Applications/ApplicationPreview/ApplicationUpdatePreviewModal';
import { ApplicationListModal } from '../pages/Applications/ApplicationsModal/ApplicationListModal';
import { CreateChildModal } from '../pages/Applications/CreateChildModal/CreateChildModal';
import { UpdateChildModal } from '../pages/Applications/CreateChildModal/UpdateChildModal';
import { CreateParentModal } from '../pages/Applications/CreateParent/CreateParentModal';
import { UpdateParentModal } from '../pages/Applications/CreateParent/UpdateParentModal';
import { AssessmentMarkbookModal } from '../pages/Assessments/AssessmentMarkbookModal/AssessmentMarkbookModal';
import { AssessmentsCreateModal } from '../pages/Assessments/AssessmentsCreateModal/AssessmentsCreateModal';
import AssessmentsPreviewModal from '../pages/Assessments/AssessmentsPreviewModal/AssessmentsPreviewModal';
import { AttendanceRegisterCreateModal } from '../pages/Attendance/AttendanceRegisterCreateModal/AttendanceRegisterCreateModal';
import { AttendanceRegisterPreviewModal } from '../pages/Attendance/AttendanceRegisterPreviewModal/AttendanceRegisterPreviewModal';
import { CheckAccessPage } from '../pages/CheckAccess/CheckAccessPage';
import { AddStudentToCompanyModal } from '../pages/Companies/AddStudentToCompanyModal/AddStudentToCompanyModal';
import { CompanyCreateModal } from '../pages/Companies/CompanyCreateModal/CompanyCreateModal';
import { CompanyPreviewModal } from '../pages/Companies/CompanyPreviewModal/CompanyPreviewModal';
import ConductCreateModal from '../pages/Conduct/ConductCreateModal/ConductCreateModal';
import { ConsentFormEditModal } from '../pages/ConsentForms/ConsentFormEditModal';
import { ConsentFormPreviewModal } from '../pages/ConsentForms/ConsentFormPreviewModal';
import { DemoSchoolCreatePage } from '../pages/DemoSchool/DemoSchoolCreate';
import { DemoSchoolCreatedModal } from '../pages/DemoSchool/DemoSchoolCreatedModal';
import { DemoSchoolRootPage } from '../pages/DemoSchool/DemoSchoolRoot';
import { EventCreateModal } from '../pages/Events/EventCreateModal/EventCreateModal';
import { EventPreviewModal } from '../pages/Events/EventPreviewModal/EventPreviewModal';
import { AddGroupModal } from '../pages/Groups/AddGroupModal/AddGroupModal';
import { GroupAttendanceStatisticsModal } from '../pages/Groups/GroupAttendanceStatisticsModal/GroupAttendanceStatisticsModal';
import { GroupPreviewModal } from '../pages/Groups/GroupPreviewModal/GroupPreviewModal';
import { GroupMembersPreview } from '../pages/Groups/GroupPreviewModal/GroupPreviewModalMembers/GroupMembers/GroupMembersPreview';
import { ManageRelationGroups } from '../pages/Groups/ManageGroupModal/ManageRelationGroups';
import { TakeAttendanceModal } from '../pages/Groups/TakeAttendanceModal/TakeAttendanceModal';
import CookiesPolicyPage from '../pages/Legal/CookiesPolicy';
import PrivacyPolicyPage from '../pages/Legal/PrivacyPolicy';
import TermsOfServicePage from '../pages/Legal/TermsOfService';
import MaintenancePage from '../pages/Maintenance';
import { MessageRoute } from '../pages/Messages/MessageRoute';
import { MessagesCreateModal } from '../pages/Messages/MessagesCreateModal/MessagesCreateModal';
import NotFound from '../pages/NotFound';
import { AddParentModal } from '../pages/Parents/AddParentModal';
import { CompareDuplicatesModal } from '../pages/ProfileModal/CompareDuplicatesModal/CompareDuplicatesModal';
import { ProfileModal } from '../pages/ProfileModal/ProfileModal';
import { StudentProductsModal } from '../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModal';
import { StudentRegistrationModal } from '../pages/ProfileModal/tabs/ProfileModalRegistrations/StudentRegistrationModal';
import { ReportCreateModal } from '../pages/Reports/ReportCreateModal/ReportCreateModal';
import { ReportsPreviewModal } from '../pages/Reports/ReportsPreviewModal/ReportsPreviewModal';
import { RolloverModal } from '../pages/Rollover/RolloverModal';
import { RolloverResult } from '../pages/Rollover/RolloverResult';
import RootPage from '../pages/Root';
import { SchoolAnnualRollover } from '../pages/School/SchoolAnnualRollover/SchoolAnnualRollover';
import { SchoolApplications } from '../pages/School/SchoolApplications/SchoolApplications';
import { SchoolAssessments } from '../pages/School/SchoolAssessmentGrades/SchoolAssessmentGrades';
import { SchoolAssessmentsGradeModal } from '../pages/School/SchoolAssessmentGrades/SchoolAssessmentsGradeModal';
import { SchoolAttendance } from '../pages/School/SchoolAttendance/SchoolAttendance';
import { AddAttendanceCodeModal } from '../pages/School/SchoolAttendance/SchoolAttendanceCodes/AddAttendanceCodeModal';
import { AttendanceCodeModal } from '../pages/School/SchoolAttendance/SchoolAttendanceCodes/AttendanceCodeModal';
import { SchoolConduct } from '../pages/School/SchoolConduct/SchoolConduct';
import { SchoolConductChangeOrderModal } from '../pages/School/SchoolConduct/SchoolConductChangeOrderModal';
import { SchoolConductTypeModal } from '../pages/School/SchoolConduct/SchoolConductTypeModal';
import { SchoolCreate } from '../pages/School/SchoolCreate/SchoolCreate';
import { SchoolCustomFields } from '../pages/School/SchoolCustomFields/SchoolCustomFields';
import { SchoolCustomFieldsModal } from '../pages/School/SchoolCustomFields/SchoolCustomFieldsModal';
import { SchoolDataImport } from '../pages/School/SchoolDataImport/SchoolDataImport';
import { SchoolFilters } from '../pages/School/SchoolFilters/SchoolFilters';
import { SchoolGeneralAddressModal } from '../pages/School/SchoolGeneral/SchoolGeneralAddress/SchoolGeneralAddressModal';
import { SchoolGeneralAgeGroupsModal } from '../pages/School/SchoolGeneral/SchoolGeneralAgeGroups/SchoolGeneralAgeGroupsModal';
import { SchoolGeneralDeleteSchoolModal } from '../pages/School/SchoolGeneral/SchoolGeneralDelete/SchoolGeneralDeleteSchoolModal';
import { SchoolGeneralDepartmentsModal } from '../pages/School/SchoolGeneral/SchoolGeneralDepartments/SchoolGeneralDepartmentsModal';
import { SchoolGeneralHousesModal } from '../pages/School/SchoolGeneral/SchoolGeneralHouses/SchoolGeneralHousesModal';
import { SchoolGeneralInformationModal } from '../pages/School/SchoolGeneral/SchoolGeneralInformationModal';
import { SchoolGeneralNameModal } from '../pages/School/SchoolGeneral/SchoolGeneralName/SchoolGeneralNameModal';
import { SchoolGeneralSchoolCreatedModal } from '../pages/School/SchoolGeneral/SchoolGeneralSchoolCreatedModal';
import { SchoolGeneralSchoolYearsModal } from '../pages/School/SchoolGeneral/SchoolGeneralSchoolYears/SchoolGeneralSchoolYearsModal';
import { SchoolGeneralStatusesModal } from '../pages/School/SchoolGeneral/SchoolGeneralStatuses/SchoolGeneralStatusesModal';
import { SchoolGeneralSubjectsModal } from '../pages/School/SchoolGeneral/SchoolGeneralSubjects/SchoolGeneralSubjectsModal';
import { SchoolNotifications } from '../pages/School/SchoolNotifications/SchoolNotifications';
import { SchoolPayments } from '../pages/School/SchoolPayments/SchoolPayments';
import { SchoolPeriods } from '../pages/School/SchoolPeriods/SchoolPeriods';
import { SchoolPeriodsUpdateModal } from '../pages/School/SchoolPeriods/SchoolPeriodsUpdate/SchoolPeriodsUpdateModal';
import { SchoolProductCreateModal } from '../pages/School/SchoolProducts/SchoolProductCreateModal/SchoolProductCreateModal';
import { SchoolProductModal } from '../pages/School/SchoolProducts/SchoolProductModal/SchoolProductModal';
import { SchoolProducts } from '../pages/School/SchoolProducts/SchoolProducts';
import { SchoolTune } from '../pages/School/SchoolTune/SchoolTune';
import { SchoolTuneInitiallyCompletedRedirect } from '../pages/School/SchoolTune/SchoolTuneInitiallyCompletedRedirect';
import { SchoolTuneUserRoleModal } from '../pages/School/SchoolTune/tabs/access/SchoolTuneUserRoleModal';
import { SchoolTuneAgeGroupsModal } from '../pages/School/SchoolTune/tabs/age_groups/SchoolTuneAgeGroupsModal';
import { SchoolTuneDepartmentsModal } from '../pages/School/SchoolTune/tabs/departments/SchoolTuneDepartmentsModal';
import { SchoolTuneHousesModal } from '../pages/School/SchoolTune/tabs/houses/SchoolTuneHousesModal';
import { SchoolTuneSchoolYearsModal } from '../pages/School/SchoolTune/tabs/school_years/SchoolTuneSchoolYearsModal';
import { SchoolTuneStatusesModal } from '../pages/School/SchoolTune/tabs/statuses/SchoolTuneStatusesModal';
import { SchoolTuneSubjectsModal } from '../pages/School/SchoolTune/tabs/subjects/SchoolTuneSubjectsModal';
import { SchoolXeroAuth } from '../pages/School/SchoolXeroAuth/SchoolXeroAuth';
import { JiraServiceWidget } from '../pages/Scripts/JiraServiceWidget';
import { SignUpCreateModal } from '../pages/SignUps/SignUpCreateModal/SignUpCreateModal';
import { SignUpPreviewModal } from '../pages/SignUps/SignUpPreviewModal/SignUpPreviewModal';
import { AddStaffModal } from '../pages/Staff/AddStaffModal/AddStaffModal';
import { CreateUserRoleModal } from '../pages/UserRoles/CreateUserRoleModal/CreateUserRoleModal';
import { ManageUserRole } from '../pages/UserRoles/ManageUserRoleModal/ManageUserRole';
import { UserRolePreviewModal } from '../pages/UserRoles/UserRolesPreviewModal/UserRolePreviewModal';
import { RouteModals } from './RouteModals';
import { RouteRequireAuth } from './RouteRequireAuth';
import { RouteRoot } from './RouteRoot';

export const AppRoutes: FC = () => {
  const { lastStackItem, backgroundPages, getBackground } = useRouter();
  const { isCompleted, isFetching, schoolId } = useSchool();
  const { showNotification, dismissNotification } = useNotifications();
  useInitiateUserCountsPolling();
  const backgroundPath = getBackground().path;
  const hasSelectedSchool = !!schoolId;

  usePortraitViewNotification({
    onClear: dismissNotification,
    onNotify: (id, onRemoveNotification) =>
      showNotification({
        id,
        persistent: true,
        icon: <RotateIcon />,
        textId: 'mobileApp-rotateToLandscape',
        actions: [
          {
            textId: 'ariaLabel-Close',
            handler: () => {
              onRemoveNotification();
              dismissNotification(id);
            },
          },
        ],
      }),
  });

  useAppLinkRedirect();

  const renderSettingsRoutes = () => {
    if (!hasSelectedSchool) return null;
    if (isCompleted && !lastStackItem?.location.pathname.startsWith('/settings/tune'))
      return (
        <Route element={<RouteModals />}>
          {/* school settings sections */}
          <Route path="assessments" element={<SchoolAssessments />}>
            <Route path="new" element={<SchoolAssessmentsGradeModal key="new" />} />
            <Route path=":id" element={<SchoolAssessmentsGradeModal />} />
          </Route>
          <Route path="xero-auth" element={<SchoolXeroAuth />} />
          <Route path="products" element={<SchoolProducts />}>
            <Route
              element={
                <WithProduct>
                  <Outlet />
                </WithProduct>
              }
            >
              <Route path="new" element={<SchoolProductCreateModal />} />
              <Route path=":id" element={<SchoolProductModal />} />
              <Route path=":id/edit" element={<SchoolProductCreateModal />} />
            </Route>
          </Route>
          <Route path="attendance" element={<SchoolAttendance />}>
            <Route path="new" element={<AddAttendanceCodeModal />} />
            <Route path=":id" element={<AddAttendanceCodeModal />} />
            <Route path="change-order" element={<AttendanceCodeModal />} />
          </Route>
          <Route path="conduct" element={<SchoolConduct />}>
            <Route path="new" element={<SchoolConductTypeModal key="new" />} />
            <Route path=":id" element={<SchoolConductTypeModal />} />
            <Route path="change-order" element={<SchoolConductChangeOrderModal />} />
          </Route>
          <Route path="filters" element={<SchoolFilters />} />
          <Route path="custom_fields" element={<SchoolCustomFields />}>
            <Route path="new" element={<SchoolCustomFieldsModal key="new" />} />
            <Route path=":id" element={<SchoolCustomFieldsModal />} />
          </Route>
          <Route path="notifications" element={<SchoolNotifications />} />
          {!IS_DEMO && <Route path="applications" element={<SchoolApplications />} />}
          <Route path="data_import" element={<SchoolDataImport />} />
          <Route path="school_periods" element={<SchoolPeriods />}>
            <Route path=":id" element={<SchoolPeriodsUpdateModal />} />
          </Route>
          <Route path="annual_rollover" element={<SchoolAnnualRollover />} />
          <Route path="payments" element={<SchoolPayments />} />
        </Route>
      );

    return (
      <Route element={<RouteModals />}>
        {/* Manually display index page for non-completed school */}
        <Route index element={backgroundPages.settings} />
        <Route path="delete" element={<SchoolGeneralDeleteSchoolModal />} />
        <Route path="tune" element={<SchoolTuneInitiallyCompletedRedirect />}>
          <Route index element={<SchoolTune />} />
          <Route path="name" element={<SchoolGeneralNameModal />} />
          <Route path="address" element={<SchoolGeneralAddressModal />} />
          <Route path="school_years" element={<SchoolTuneSchoolYearsModal />} />
          <Route path="age_groups" element={<SchoolTuneAgeGroupsModal />} />
          <Route path="statuses" element={<SchoolTuneStatusesModal />} />
          <Route path="info" element={<SchoolGeneralInformationModal />} />
          <Route path="user_roles">
            {!IS_DEMO && <Route path="create" element={<SchoolTuneUserRoleModal />} />}
            <Route path=":id">
              <Route index element={<SchoolTuneUserRoleModal type="preview" />} />
              <Route path="assign" element={<SchoolTuneUserRoleModal type="assign" />} />
              <Route path="edit" element={<SchoolTuneUserRoleModal />} />
            </Route>
          </Route>
          <Route path="subjects" element={<SchoolTuneSubjectsModal />} />
          <Route path="houses" element={<SchoolTuneHousesModal />} />
          <Route path="departments" element={<SchoolTuneDepartmentsModal />} />
        </Route>
      </Route>
    );
  };

  const renderRoutes = () => {
    if (!hasSelectedSchool) return null;
    if (isFetching) return <Route path="*" element={<Loading />} />;
    if (!isCompleted) return <Route path="*" element={<Navigate to="/settings" />} />;

    return (
      <Route element={<RouteModals withBackground />}>
        <Route path="/settings">
          {/* Display index page */}
          <Route index element={null} />

          {/* School settings modals */}
          <Route path="name" element={<SchoolGeneralNameModal />} />
          <Route path="info" element={<SchoolGeneralInformationModal />} />
          <Route path="delete" element={<SchoolGeneralDeleteSchoolModal />} />
          <Route path="address" element={<SchoolGeneralAddressModal />} />
          <Route path="school_years" element={<SchoolGeneralSchoolYearsModal />} />
          <Route path="age_groups" element={<SchoolGeneralAgeGroupsModal />} />
          <Route path="statuses" element={<SchoolGeneralStatusesModal />} />
          <Route path="subjects" element={<SchoolGeneralSubjectsModal />} />
          <Route path="houses" element={<SchoolGeneralHousesModal />} />
          <Route path="departments" element={<SchoolGeneralDepartmentsModal />} />
        </Route>

        <Route path="/planner">
          <Route path="school_years" element={<SchoolGeneralSchoolYearsModal />} />
          <Route path="assessments/:id" element={<AssessmentsPreviewModal />} />
        </Route>

        <Route path="/students">
          <Route
            path=":id"
            element={
              <WithProfileWrapper userType="student" basePath="/students">
                <Outlet />
              </WithProfileWrapper>
            }
          >
            <Route index element={<ProfileModal />} />
            <Route path="registration" element={<StudentRegistrationModal />} />
            <Route path="payers" element={<StudentProductsModal />} />
            <Route path="compare">
              <Route path=":duplicateId" element={<CompareDuplicatesModal />} />
            </Route>
            <Route path="groups" element={<ManageRelationGroups />} />
          </Route>
          <Route
            path="school_created"
            element={IS_DEMO ? <DemoSchoolCreatedModal /> : <SchoolGeneralSchoolCreatedModal />}
          />
          <Route path="rollover">
            <Route index element={<RolloverModal />} />
            <Route path="result" element={<RolloverResult path={'/students'} />} />
          </Route>
        </Route>

        <Route path="/parents">
          <Route
            path=":id"
            element={
              <WithProfileWrapper userType="parent" basePath="/parents">
                <ProfileModal />
              </WithProfileWrapper>
            }
          />
          <Route
            path="new"
            element={
              <WithApplication>
                <AddParentModal />
              </WithApplication>
            }
          />
        </Route>

        <Route path="/staff">
          <Route
            path=":id"
            element={
              <WithProfileWrapper userType="staff" basePath="/staff">
                <Outlet />
              </WithProfileWrapper>
            }
          >
            <Route index element={<ProfileModal />} />
            <Route path="user-roles" element={<ManageUserRole />} />
            <Route path="groups" element={<ManageRelationGroups />} />
          </Route>
          <Route
            path="new"
            element={
              <WithApplication>
                <AddStaffModal />
              </WithApplication>
            }
          />
        </Route>

        <Route path="/children">
          <Route
            path=":id"
            element={
              <WithProfileWrapper userType="child" basePath="/students">
                <ProfileModal />
              </WithProfileWrapper>
            }
          />
        </Route>

        <Route path="/adults">
          <Route
            path=":id"
            element={
              <WithProfileWrapper userType="adult" basePath={backgroundPath ?? '/parents'}>
                <ProfileModal />
              </WithProfileWrapper>
            }
          />
        </Route>

        <Route
          path="/companies"
          element={
            <WithCompany>
              <Outlet />
            </WithCompany>
          }
        >
          <Route path="new" element={<CompanyCreateModal />} />
          <Route path=":id">
            <Route index element={<CompanyPreviewModal />} />
            <Route path="edit" element={<CompanyCreateModal />} />
            <Route path="add-student" element={<AddStudentToCompanyModal />} />
          </Route>
        </Route>

        <Route path="/messages">
          <Route
            path=":id"
            element={
              <WithMessageWrapper>
                <Outlet />
              </WithMessageWrapper>
            }
          >
            <Route index element={<MessageRoute />} />
            <Route path="edit" element={<MessagesCreateModal />} />
          </Route>
        </Route>

        <Route path="/groups">
          <Route path="new" element={<AddGroupModal />} />
          <Route path=":id/edit" element={<AddGroupModal />} />
          <Route
            path=":id"
            element={
              <WithGroup>
                <WithSelectIds>
                  <Outlet />
                </WithSelectIds>
              </WithGroup>
            }
          >
            <Route index element={<GroupPreviewModal />} />
            <Route path="members" element={<Outlet />}>
              <Route path="students" element={<GroupMembersPreview userType="student" />} />
            </Route>

            <Route path="attendance">
              <Route index element={<TakeAttendanceModal />} />

              <Route
                path="statistics"
                element={
                  <WithGroupAttendanceStatistics>
                    <GroupAttendanceStatisticsModal />
                  </WithGroupAttendanceStatistics>
                }
              />

              <Route path=":attendanceId" element={<TakeAttendanceModal />} />
            </Route>

            <Route path="markbook" element={<AssessmentMarkbookModal />} />
          </Route>
        </Route>

        <Route path="/assessments">
          <Route path="new" element={<AssessmentsCreateModal />} />
          <Route path=":id">
            <Route index element={<AssessmentsPreviewModal />} />
            <Route path="edit" element={<AssessmentsCreateModal />} />
          </Route>
        </Route>

        <Route path="/reports">
          <Route
            path="new"
            element={
              <WithReport>
                <ReportCreateModal />
              </WithReport>
            }
          />
          <Route
            path=":id"
            element={
              <WithReport>
                <Outlet />
              </WithReport>
            }
          >
            <Route index element={<ReportsPreviewModal />} />
            <Route path="edit" element={<ReportCreateModal />} />
          </Route>
        </Route>

        <Route path="/conduct">
          <Route path="new" element={<ConductCreateModal />} />
          <Route path=":id">
            <Route index element={null} />
            <Route path="edit" element={<ConductCreateModal />} />
          </Route>
        </Route>
        <Route
          path="/userroles"
          element={
            <WithUserRolesForSchool>
              <Outlet />
            </WithUserRolesForSchool>
          }
        >
          {!IS_DEMO && <Route path="new" element={<CreateUserRoleModal />} />}
          <Route path=":id">
            <Route index element={<UserRolePreviewModal />} />
            <Route path="assign" element={<CreateUserRoleModal assign />} />
            <Route path="edit" element={<CreateUserRoleModal />} />
          </Route>
        </Route>

        <Route
          path="/applications"
          element={
            <WithApplication>
              <Outlet />
            </WithApplication>
          }
        >
          <Route index element={<ApplicationListModal />} />
          <Route path=":id">
            <Route index element={<ApplicationPreviewModal />} />
            <Route path="convert" element={<ApplicationAddStudentsModal />} />
            <Route
              path="update"
              element={
                <ApplicationAccessLayer checkEditPermissions>
                  <Outlet />
                </ApplicationAccessLayer>
              }
            >
              <Route index element={<ApplicationUpdateModal />} />
              <Route path="general" element={<ApplicationUpdateGeneralInfoModal />} />

              <Route path="preview" element={<ApplicationUpdatePreviewModal />} />
              <Route path="child" element={<UpdateChildModal />}>
                <Route path=":childId" element={<UpdateChildModal />} />
              </Route>
              <Route path="parent" element={<UpdateParentModal />}>
                <Route path=":parentId" element={<UpdateParentModal />} />
              </Route>
            </Route>
          </Route>

          <Route
            path="new"
            element={
              <ApplicationAccessLayer checkEditPermissions>
                <Outlet />
              </ApplicationAccessLayer>
            }
          >
            <Route path="preview" element={<ApplicationCreatePreviewModal />} />
            <Route path="general" element={<ApplicationCreateGeneralInfoModal />} />
            <Route index element={<ApplicationCreateModal />} />
            <Route path="child" element={<CreateChildModal />}>
              <Route path=":childId" element={<CreateChildModal />} />
            </Route>
            <Route path="parent" element={<CreateParentModal />}>
              <Route path=":parentId" element={<CreateParentModal />} />
            </Route>
          </Route>
        </Route>

        <Route path="/attendance">
          <Route path="new" element={<AttendanceRegisterCreateModal />} />
          <Route
            path=":id"
            element={
              <WithAttendanceRegister>
                <Outlet />
              </WithAttendanceRegister>
            }
          >
            <Route index element={<AttendanceRegisterPreviewModal />} />
            <Route path="edit" element={<AttendanceRegisterCreateModal />} />
          </Route>
        </Route>

        <Route>
          <Route path="user-roles">
            <Route path=":id">
              <Route index element={<UserRolePreviewModal />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="/events"
          element={
            <WithRecurringConfirmDialog>
              <WithEvent>
                <Outlet />
              </WithEvent>
            </WithRecurringConfirmDialog>
          }
        >
          <Route path="new" element={<EventCreateModal />} />
          <Route path=":id">
            <Route index element={<EventPreviewModal />} />
            <Route path="edit" element={<EventCreateModal />} />
          </Route>
        </Route>
        <Route
          path="/signups"
          element={
            <WithSignUp>
              <Outlet />
            </WithSignUp>
          }
        >
          <Route path="new" element={<SignUpCreateModal />} />
          <Route path=":id">
            <Route index element={<SignUpPreviewModal />} />
            <Route path="edit" element={<SignUpCreateModal />} />
          </Route>
        </Route>
        <Route path="/consent-form" element={<Outlet />}>
          <Route path=":id">
            <Route index element={<ConsentFormPreviewModal />} />
            <Route path="edit" element={<ConsentFormEditModal />} />
          </Route>
        </Route>
      </Route>
    );
  };

  return (
    <Routes>
      {/* Plain routes not in stack */}
      <Route element={<RouteRoot />}>
        <Route element={<JiraServiceWidget />}>
          <Route element={<RouteRequireAuth />}>
            <Route path="/planner/*" element={backgroundPages.planner} />

            <Route path="/adults/*" element={backgroundPages.adults} />
            <Route path="/children/*" element={backgroundPages.children} />

            <Route path="/companies/*" element={backgroundPages.companies} />

            <Route path="/groups/*" element={backgroundPages.groups} />

            <Route path="/assessments/*" element={backgroundPages.assessments} />
            <Route path="/messages/*" element={backgroundPages.messages} />
            <Route path="/reports/*" element={backgroundPages.reports} />
            <Route path="/attendance/*" element={backgroundPages.attendance} />
            <Route path="/conduct/*" element={backgroundPages.conduct} />
            <Route path="/events/*" element={backgroundPages.events} />
            <Route path="/singups/*" element={backgroundPages.signups} />

            <Route path="/userroles/*" element={backgroundPages.userroles} />
            <Route path="/check-access" element={<CheckAccessPage />} />
          </Route>
        </Route>

        <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      </Route>

      {/* Modals in stack */}
      <Route element={<JiraServiceWidget />}>
        <Route element={<RouteRequireAuth />}>
          <Route path="/school">
            <Route index element={<Navigate to="/" />} />
            {!IS_DEMO && <Route path="new" element={<SchoolCreate />} />}
          </Route>
          <Route path="/settings">
            {/* Only School create and School tune (post-create) modals here. School general page
             * is being rendered below within <RouteModals /> as a background
             */}
            {renderSettingsRoutes()}
          </Route>

          {renderRoutes()}
        </Route>
      </Route>

      <Route path="/maintenance" element={<MaintenancePage />} />
      {IS_DEMO && <Route path="/create-demo-school" element={<DemoSchoolCreatePage />} />}
      <Route path="/" element={IS_DEMO ? <DemoSchoolRootPage /> : <RootPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
