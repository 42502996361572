import { Button, Stack, Typography } from '@mui/material';
import { useCheckXeroAuthQuery, XeroAuthState } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { ArrowRightIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';

import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { allowRenderSchoolPaymentsSettings } from '../../../helpers/renderRoutesByPermissions';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolProductsContent } from './SchoolProductsContent';
import { SchoolProductsSkeleton } from './SchoolProductsSkeleton';

export const SchoolProducts: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId = '', permissions } = useAuth();
  const { showError } = useNotifications();

  const canView = permissions.includes('product_and_invoice_viewer');
  const hasPaymentsSettingsAccess = allowRenderSchoolPaymentsSettings(permissions);

  const { data: authData } = useCheckXeroAuthQuery(
    {
      schoolId,
    },
    { enabled: !!schoolId, refetchOnMount: 'always', onError: showError, retry: 0 },
  );

  const renderContent = () => {
    if (!canView) {
      return <AccessDeniedPage />;
    }

    if (!authData) {
      return <SchoolProductsSkeleton />;
    }

    if (authData.state !== XeroAuthState.Ready)
      return (
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <Typography variant="h1" textAlign="center" mb={1} maxWidth={400}>
            {formatMessage({ id: 'products-pleaseAuthXeroTitle' })}
          </Typography>
          <Typography variant="h3" maxWidth={400} mb={3} textAlign="center">
            {formatMessage({ id: 'products-pleaseAuthXeroDescription' })}
          </Typography>

          {hasPaymentsSettingsAccess && (
            <Link to="/settings/payments">
              <Button endIcon={<ArrowRightIcon />}>
                {formatMessage({ id: 'section-PaymentsSettings' })}
              </Button>
            </Link>
          )}
        </Stack>
      );

    return <SchoolProductsContent />;
  };

  return (
    <MainLayout>
      {renderContent()}
      <Outlet />
    </MainLayout>
  );
};
