import {
  Icon,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { PaymentFrequencyType, Product, useGetSchoolPaymentFrequencies } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowAngleIcon,
  DropdownIcon,
  FullDayIcon,
  GridCell,
  GridHead,
  HalfDayIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { renderAccountName } from './helpers';
import { ProductApplicable } from './ProductsApplicable';

export const ProductsHeader: FC<TableHeadProps> = ({ ...rest }) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="20px" />
      <GridCell>{$t({ id: 'products-Name' })} </GridCell>
      <GridCell width="30px" />
      <GridCell width="150px">{$t({ id: 'products-Account' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Trigger' })}</GridCell>
      <GridCell width="110px">{$t({ id: 'products-Product-Required' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Frequency-weekly' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Frequency-monthly' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Frequency-termly' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Frequency-biannually' })}</GridCell>
      <GridCell width="120px">{$t({ id: 'products-Frequency-annually' })}</GridCell>
      <GridCell width="40px" />
    </GridHead>
  );
};

type ProductRowProps = {
  product: Product;
  schoolId: string;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  currencySymbol: string;
};

export const ProductRow: FC<ProductRowProps> = ({ product, schoolId, currencySymbol }) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { $t } = useIntl();
  const [isExpanded, expand, collapse] = useFlag();
  const { data } = useGetSchoolPaymentFrequencies(schoolId);
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const [rowHovered, setRowHovered] = useState(false);

  const { name, id, types, account, trigger, obligatory } = product;

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: (theme: Theme) => ({
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    }),
  };

  const borderBottomOfFirstRow = isExpanded ? false : undefined;

  return (
    <>
      <TableRow ref={ref} component={Link} to={`/settings/products/${id}`} {...hoverProps}>
        <GridCell borderBottom={borderBottomOfFirstRow} />
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <TypographyWithOverflowHint variant="h3" color="text.primary">
            {name}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow} />
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <TypographyWithOverflowHint color="text.primary">
            {renderAccountName(account)}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <TypographyWithOverflowHint color="text.primary">
            {propertiesMap.status.find((s) => s.id === trigger.extra_data.status)?.name}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <Typography color="text.primary">{$t({ id: obligatory ? 'yes' : 'no' })}</Typography>
        </GridCell>
        <GridCell colSpan={5} borderBottom={borderBottomOfFirstRow} />
        <GridCell
          borderBottom={borderBottomOfFirstRow}
          onClick={(e) => {
            e.preventDefault();
            isExpanded ? collapse() : expand();
          }}
        >
          {!!types.length && (
            <IconButton
              inverse
              sx={(theme) => ({
                mr: 1.5,
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                color: rowHovered ? theme.palette.common.grey2 : undefined,
              })}
            >
              <DropdownIcon />
            </IconButton>
          )}
        </GridCell>
      </TableRow>
      <>
        {isExpanded &&
          types.map((type, typeIndex, types) => {
            const isLastType = typeIndex === types.length - 1;
            const isFirstType = typeIndex === 0;
            return (
              <>
                <TableRow
                  key={type.id}
                  component={Link}
                  to={`/settings/products/${id}`}
                  {...hoverProps}
                >
                  <GridCell borderBottom={false} />
                  <GridCell borderBottom={false} p={0} pl={1}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <Icon
                        sx={(theme) => ({
                          color: rowHovered
                            ? theme.palette.common.grey2
                            : theme.palette.common.grey,
                        })}
                      >
                        <ArrowAngleIcon />
                      </Icon>
                      <Stack
                        width="100%"
                        py={2}
                        sx={(theme) => ({
                          borderTop: `1px solid ${theme.palette.divider}`,
                        })}
                      >
                        <Typography variant="h3" color="text.primary">
                          {type.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </GridCell>
                  <GridCell borderBottom={false} colSpan={9} borderTop={isExpanded} />
                  <GridCell borderBottom={false} borderTop={isExpanded && !isFirstType} />
                </TableRow>
                {type.variants.map((variant, index, variants) => {
                  const isLastVariant = index === variants.length - 1;

                  return (
                    <TableRow
                      key={variant.id}
                      component={Link}
                      to={`/settings/products/${id}`}
                      {...hoverProps}
                    >
                      <GridCell
                        colSpan={2}
                        borderBottom={isLastVariant && isLastType ? undefined : false}
                      />
                      <GridCell borderBottom={isLastVariant && isLastType ? undefined : false}>
                        <Icon
                          sx={(theme) => ({
                            color: rowHovered
                              ? theme.palette.primary.main
                              : theme.palette.common.grey2,
                          })}
                        >
                          {variant.half_day ? (
                            <Tooltip
                              title={$t({
                                id: 'products-ThisProductVariantAppliesToHalfDayStudents',
                              })}
                            >
                              <Icon>
                                <HalfDayIcon />
                              </Icon>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={$t({ id: 'products-ThisProductVariantAppliesToAllStudents' })}
                            >
                              <Icon>
                                <FullDayIcon />
                              </Icon>
                            </Tooltip>
                          )}
                        </Icon>
                      </GridCell>
                      <GridCell
                        colSpan={3}
                        borderBottom={!isLastType && isLastVariant ? false : undefined}
                      >
                        <ProductApplicable
                          pt={0.25}
                          color="text.primary"
                          schoolId={schoolId}
                          selectedAgeGroupIds={variant.age_groups}
                          selectedSubjectIds={variant.subjects}
                        />
                      </GridCell>
                      {[
                        PaymentFrequencyType.Weekly,
                        PaymentFrequencyType.Monthly,
                        PaymentFrequencyType.Termly,
                        PaymentFrequencyType.Biannually,
                        PaymentFrequencyType.Annually,
                      ].map((f) => {
                        const price = getVariantPrice(
                          variant,
                          currencySymbol,
                          data?.find(({ frequency_type }) => frequency_type === f)?.id,
                        );
                        return (
                          <GridCell
                            key={f}
                            borderBottom={!isLastType && isLastVariant ? false : undefined}
                          >
                            <Typography
                              color="text.primary"
                              sx={{
                                ...(!price && {
                                  '&&&.MuiTypography-root': {
                                    color: 'text.secondary',
                                  },
                                }),
                              }}
                            >
                              {price || '-'}
                            </Typography>
                          </GridCell>
                        );
                      })}
                      <GridCell borderBottom={isLastVariant && isLastType ? undefined : false} />
                    </TableRow>
                  );
                })}
              </>
            );
          })}
      </>
    </>
  );
};

const getVariantPrice = (
  v: Product['types'][0]['variants'][0],
  currencySymbol: string,
  freqId?: string,
) => {
  if (!freqId) return '';

  const p = v.prices.find((v) => v.frequency_id === freqId);
  if (!p) return '';

  return `${currencySymbol} ${p.price}`;
};
