import { SchoolInviteStatus } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { Company } from './apiTypes/companies';
import { IColumnSort } from './apiTypes/endpoints/people';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import { WithAvatar, WithName } from './apiTypes/partials';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SchoolYear } from './apiTypes/schools';
import { FilterKeys } from './apiTypes/users';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

export type XeroAccount = {
  id: string;
  name: string;
  tax_amount_percent: number; // 0-100
  xero_tenant_name: string;
  xero_tenant_id: string;
};

export enum ProductTrigger {
  RegistrationUpdate = 'registration-update',
}

export enum TaxType {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
  NoTax = 'no_tax',
}

export enum PaymentFrequencyType {
  Weekly = 'weekly', // Mon
  Monthly = 'monthly', // On 20
  Termly = 'termly', // Nov 20, Jan 20, Mar 20
  Annually = 'annually', // May 15
  Biannually = 'biannually', // May 15, Sep 15
}

type PaymentFrequencySingeDate = {
  frequency_type:
    | PaymentFrequencyType.Annually
    | PaymentFrequencyType.Weekly
    | PaymentFrequencyType.Monthly;
  extra_data: {
    day: number;
  };
};

type PaymentFrequencyMultiDate = {
  frequency_type: PaymentFrequencyType.Termly | PaymentFrequencyType.Biannually;
  extra_data: {
    days: number[];
  };
};

export type PaymentFrequency = {
  id: string;
  due_date: number;
} & (PaymentFrequencySingeDate | PaymentFrequencyMultiDate);

export type ProductVariantPrice = {
  price: number;
  frequency_id: string;
};

export type ProductVariant = {
  id: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

export type ProductType = {
  id: string;
  name: string;
  variants: ProductVariant[];
  active_from: string;
  active_to: string;
};

export type Product = {
  id: string;
  school_id: string;
  name: string;
  description: string;
  account: XeroAccount;
  obligatory: boolean;
  unique_fee_selection: boolean;
  tax_type: TaxType;
  trigger: {
    id: string;
    trigger_type: ProductTrigger;
    extra_data: { status: string };
  };
  types: ProductType[];
};

export type ProductSaveVariant = {
  id?: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

export type ProductSaveType = {
  id?: string;
  name: string;
  variants: ProductSaveVariant[];
  year_id?: SchoolYear['id'];
  active_from?: string;
  active_to?: string;
};

export type ProductSave = {
  name: string;
  description: string;
  account_id: string;
  obligatory: boolean;
  tax_type: TaxType;
  unique_fee_selection: boolean;
  trigger: {
    trigger_type: ProductTrigger;
    extra_data: { status: string };
  };
  types: ProductSaveType[];
};

const DEFAULT_PAGE_SIZE = 30;
const INVOICES_URL = '/invoices';

export type GetSchoolAccountsResponse = XeroAccount[];

export const getSchoolAccounts = async (schoolId: string): Promise<GetSchoolAccountsResponse> => {
  return await api.get(`${INVOICES_URL}/accounts/${schoolId}`);
};

export const GET_SCHOOL_ACCOUNTS = `${INVOICES_URL}/GET_SCHOOL_ACCOUNTS`;

export const useGetSchoolAccounts = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolAccountsResponse>,
) => {
  return useQuery<GetSchoolAccountsResponse, ApiError>(
    [GET_SCHOOL_ACCOUNTS, schoolId],
    () => getSchoolAccounts(schoolId),
    {
      ...options,
    },
  );
};

export type GetSchoolPaymentFrequenciesResponse = PaymentFrequency[];

export const getSchoolPaymentFrequencies = async (
  schoolId: string,
): Promise<GetSchoolPaymentFrequenciesResponse> => {
  return await api.get(`${INVOICES_URL}/frequencies/${schoolId}`);
};

export const GET_SCHOOL_PAYMENT_FREQUENCIES = `${INVOICES_URL}/GET_SCHOOL_PAYMENT_FREQUENCIES`;

export const useGetSchoolPaymentFrequencies = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolPaymentFrequenciesResponse>,
) => {
  return useQuery<GetSchoolPaymentFrequenciesResponse, ApiError>(
    [GET_SCHOOL_PAYMENT_FREQUENCIES, schoolId],
    () => getSchoolPaymentFrequencies(schoolId),
    {
      ...options,
    },
  );
};

export const getProduct = async (id: string): Promise<Product> => {
  return api.get(`${INVOICES_URL}/products/${id}`);
};

export const GET_PRODUCT_QUERY = `${INVOICES_URL}/GET_PRODUCT_QUERY`;

export const useGetProductQuery = (id: string, options?: RQUseQueryOptions<Product>) => {
  return useQuery<Product, ApiError>([GET_PRODUCT_QUERY, id], () => getProduct(id), {
    ...options,
  });
};

export type GetProductsSort = IColumnSort<keyof Pick<Product, 'name'>>;

export type GetProductsParams = {
  schoolId: string;
  query: string;
  pageSize?: number;
  sort?: GetProductsSort[];
  pageNumber?: number;
  relationId?: string;
};

export const getProducts = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  relationId,
}: GetProductsParams): Promise<PagedResponse<Product>> => {
  return api.get(`${INVOICES_URL}/products/for-school/${schoolId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      relation_id: relationId,
    },
  });
};

export const GET_PRODUCTS_QUERY = `${INVOICES_URL}/GET_PRODUCTS_QUERY`;

export const useGetProductsListQuery = (
  initialParams: Omit<GetProductsParams, 'sort'> & {
    sort?: GetProductsSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Product>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Product>, ApiError>(
    [GET_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getProducts({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type CreateProductParams = {
  schoolId: string;
  product: ProductSave;
};

export type CreateProductResponse = { success: string; product: Product };

export const createProduct = ({
  schoolId,
  ...params
}: CreateProductParams): Promise<CreateProductResponse> => {
  return api.post(`${INVOICES_URL}/products/for-school/${schoolId}`, {
    ...params.product,
    tax_inclusive: undefined,
  });
};

export const useCreateProductMutation = (
  options?: RQUseMutationOptions<CreateProductResponse, CreateProductParams>,
): RQUseMutationResult<CreateProductResponse, CreateProductParams> => {
  return useMutation(createProduct, {
    ...options,
  });
};

export type UpdateProductParams = {
  id: string;
  product: ProductSave;
};

export type UpdateProductResponse = { success: string; product: Product };

export const updateProduct = ({
  id,
  ...params
}: UpdateProductParams): Promise<UpdateProductResponse> => {
  return api.patch(`${INVOICES_URL}/products/${id}`, {
    ...params.product,
    tax_inclusive: undefined,
  });
};

export const useUpdateProductMutation = (
  options?: RQUseMutationOptions<UpdateProductResponse, UpdateProductParams>,
): RQUseMutationResult<UpdateProductResponse, UpdateProductParams> => {
  return useMutation(updateProduct, {
    ...options,
  });
};

export type DeleteProductParams = {
  id: string;
};

export type DeleteProductResponse = { success: string };

export const deleteProduct = ({ id }: DeleteProductParams): Promise<DeleteProductResponse> => {
  return api.remove(`${INVOICES_URL}/products/${id}`);
};

export const useDeleteProductMutation = (
  options?: RQUseMutationOptions<DeleteProductResponse, DeleteProductParams>,
): RQUseMutationResult<DeleteProductResponse, DeleteProductParams> => {
  return useMutation(deleteProduct, {
    ...options,
  });
};

type CheckProductNameUniqueParams = {
  schoolId: string;
  name: string;
};

type CheckProductNameUniqueResponse = {
  is_unique: boolean;
};

const checkProductNameUnique = (
  params: CheckProductNameUniqueParams,
): Promise<CheckProductNameUniqueResponse> => {
  return api.post(`${INVOICES_URL}/products/for-school/${params.schoolId}/validate-name`, {
    name: params.name,
  });
};

export const useCheckProductNameUniqueMutation = (
  options?: RQUseMutationOptions<CheckProductNameUniqueResponse, CheckProductNameUniqueParams>,
): RQUseMutationResult<CheckProductNameUniqueResponse, CheckProductNameUniqueParams> => {
  return useMutation(checkProductNameUnique, {
    ...options,
  });
};

export enum PayerType {
  Default = 'default',
  Company = 'company',
}

export type DefaultPayer = {
  id: string;
  email?: string;
  telephone?: string;
  invite_status: SchoolInviteStatus | null;
} & WithName &
  WithAvatar;

export type StudentProduct = {
  id: string;
  name: string;
  obligatory: boolean;
  type_name: string;
  price: number;
  frequency_id: string;
  payer_type: PayerType;
  discount_percent?: number;
  discounted_price?: number;
  variant_id: string;
};

export type StudentProductWithVariants = StudentProduct & {
  available_variants: StudentProductVariant[];
};

export type StudentProductVariant = {
  type_id: string;
  type_name: string;
  id: string;
  half_day: boolean;
  prices: ProductVariantPrice[];
};

type GetStudentProductsParams = {
  relationId: string;
};

type GetStudentProductsResponse = {
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: StudentProductWithVariants[];
};

const getStudentProducts = async ({
  relationId,
}: GetStudentProductsParams): Promise<GetStudentProductsResponse> => {
  return api.get(`${INVOICES_URL}/products/for-relation/${relationId}`);
};

export const GET_STUDENT_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENT_PAYERS_QUERY`;

export const useGetStudentProductsQuery = (
  params: GetStudentProductsParams,
  options?: RQUseQueryOptions<GetStudentProductsResponse>,
) => {
  return useQuery<GetStudentProductsResponse, ApiError>(
    [GET_STUDENT_PRODUCTS_QUERY, params],
    () => getStudentProducts(params),
    {
      ...options,
    },
  );
};

export interface StudentWithProducts extends WithName, WithAvatar {
  id: string;
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: StudentProduct[];
}

type GetStudentsWithProductsSort = IColumnSort<
  keyof Pick<StudentWithProducts, 'last_name' | 'given_name'>
>;

interface GetStudentsWithProductsFilters {
  [FilterKeys.Status]?: string[];
  [FilterKeys.AgeGroup]?: string[];
}

interface GetStudentsWithProductsParams {
  schoolId: string;
  query: string;
  pageSize?: number;
  sort?: GetStudentsWithProductsSort[];
  pageNumber?: number;
  filters?: Partial<GetStudentsWithProductsFilters>;
}

const getStudentsWithProducts = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  filters,
}: GetStudentsWithProductsParams): Promise<PagedResponse<StudentWithProducts>> => {
  const baseQueryParams = filters
    ? removeObjectUndefinedNullValues({
        status_ids: filters?.status?.join(','),
        age_group: filters?.age_group?.join(','),
      })
    : {};

  return api.get(`${INVOICES_URL}/students/for-school/${schoolId}`, {
    params: {
      ...baseQueryParams,
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
    },
  });
};

export const GET_STUDENTS_WITH_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENTS_WITH_PRODUCTS_QUERY`;

export const useGetStudentsWithProductsListQuery = (
  initialParams: Omit<GetStudentsWithProductsParams, 'sort'> & {
    sort?: GetStudentsWithProductsSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentWithProducts>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = params.filters ? removeObjectEmptyArrayValues(params.filters) : {};

  const query = useInfiniteQuery<PagedResponse<StudentWithProducts>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsWithProducts({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export interface StudentForCompanyRelation extends WithName, WithAvatar {
  id: string;
}

export interface StudentForCompany {
  relation: StudentForCompanyRelation;
  products: StudentProduct[];
}

type GetStudentsForCompanySort = IColumnSort<
  keyof Pick<StudentForCompanyRelation, 'last_name' | 'given_name'>
>;

interface GetStudentsForCompanyParams {
  query: string;
  pageSize?: number;
  sort?: GetStudentsForCompanySort[];
  pageNumber?: number;
  companyId: Company['id'];
  year_id?: string;
}

export const getStudentsForCompany = async ({
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  companyId,
  year_id,
}: GetStudentsForCompanyParams): Promise<PagedResponse<StudentForCompany>> => {
  return api.get(`${INVOICES_URL}/students/for-company/${companyId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
      year_id,
    },
  });
};

export const GET_STUDENTS_FOR_COMPANY_QUERY = `${INVOICES_URL}/GET_STUDENTS_FOR_COMPANY_QUERY`;

export const useGetStudentsForCompanyListQuery = (
  initialParams: Omit<GetStudentsForCompanyParams, 'sort'> & {
    sort?: GetStudentsForCompanySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentForCompany>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<StudentForCompany>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsForCompany({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type StudentProductSave = {
  id: string;
  type_name: string;
  variant_id: string;
  frequency_id: string;
  discount_percent?: number;
  payer_type: PayerType;
};

type UpdateStudentProductsParams = {
  studentId: string;
  defaultPayerId: string;
  companyPayerId?: string;
  products: StudentProductSave[];
};

type UpdateStudentProductsResponse = { success: string };

export const updateStudentProducts = ({
  studentId,
  defaultPayerId,
  companyPayerId,
  products,
}: UpdateStudentProductsParams): Promise<UpdateStudentProductsResponse> => {
  return api.patch(`${INVOICES_URL}/products/for-relation/${studentId}`, {
    default_payer_id: defaultPayerId,
    company_payer_id: companyPayerId,
    products,
  });
};

export const useUpdateStudentProductsMutation = (
  options?: RQUseMutationOptions<UpdateStudentProductsResponse, UpdateStudentProductsParams>,
): RQUseMutationResult<UpdateStudentProductsResponse, UpdateStudentProductsParams> => {
  return useMutation(updateStudentProducts, {
    ...options,
  });
};

export type DependantStudent = {
  id: string;
} & WithName &
  WithAvatar;

type GetDependantStudentsForParentParams = {
  id: string;
  year_id?: string;
};

export const getDependantStudentsForParent = async ({
  id,
  year_id,
}: GetDependantStudentsForParentParams): Promise<DependantStudent[]> => {
  return api.get(`${INVOICES_URL}/students/for-parent/${id}`, {
    params: { year_id },
  });
};

export const GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY`;

export const useGetDependantStudentsForParentQuery = (
  params: GetDependantStudentsForParentParams,
  options?: RQUseQueryOptions<DependantStudent[]>,
) => {
  return useQuery<DependantStudent[], ApiError>(
    [GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY, params],
    () => getDependantStudentsForParent(params),
    {
      ...options,
    },
  );
};

type GetProductsForParentParams = {
  relationId: string;
};

type GetProductsForParentResponse = {
  student_id: string;
  product: StudentProduct;
}[];

const getProductsForParent = async ({
  relationId,
}: GetProductsForParentParams): Promise<GetProductsForParentResponse> => {
  return api.get(`${INVOICES_URL}/products/for-parent/${relationId}`);
};

export const GET_PRODUCTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_PRODUCTS_FOR_PARENT_QUERY`;

export const useGetProductsForParentQuery = (
  params: GetProductsForParentParams,
  options?: RQUseQueryOptions<GetProductsForParentResponse>,
) => {
  return useQuery<GetProductsForParentResponse, ApiError>(
    [GET_PRODUCTS_FOR_PARENT_QUERY, params],
    () => getProductsForParent(params),
    {
      ...options,
    },
  );
};

export type PayableFeeStatus =
  | 'upcoming'
  | 'unpaid'
  | 'partially_paid'
  | 'overdue'
  | 'paid'
  | 'voided'
  | 'cancelled';

export type GetPayableFeesStatisticsParams = {
  school_ids: string;
  year_id: string;
  relation_ids?: string[]; // any school user relation id
  query?: string;
  company_ids?: string[];
};

type GetPayableFeesStatisticsResponse = {
  statistics: Array<{ label: PayableFeeStatus; value: number }>;
  total: number;
};

const getPayableFeesStatistics = async ({
  relation_ids,
  company_ids,
  ...rest
}: GetPayableFeesStatisticsParams): Promise<GetPayableFeesStatisticsResponse> => {
  const params = removeObjectUndefinedNullValues({
    relation_ids: relation_ids?.join(','),
    company_ids: company_ids?.join(','),
    ...rest,
  });

  return api.get(`${INVOICES_URL}/invoice/for-school/statistics`, {
    params,
  });
};

export const GET_PAYABLE_FEES_STATISTICS = `${INVOICES_URL}/GET_PAYABLE_FEES_STATISTICS`;

export const useGetPayableFeesStatisticsQuery = (
  params: GetPayableFeesStatisticsParams,
  options?: RQUseQueryOptions<GetPayableFeesStatisticsResponse>,
) => {
  return useQuery<GetPayableFeesStatisticsResponse, ApiError>(
    [GET_STUDENT_PRODUCTS_QUERY, params],
    () => getPayableFeesStatistics(params),
    {
      ...options,
    },
  );
};
type GetPayableFeesParams = {
  school_ids: string;
  year_id: string;
  relation_ids?: string[]; // any school user relation id
  query?: string;
  company_ids?: string[];
  page_number?: number;
  page_size?: number;
};

type PayableFeePayer =
  | {
      type: PayerType.Company;
      data: Company;
    }
  | {
      type: PayerType.Default;
      data: DefaultPayer;
    };

export type FeeItem = {
  product_id: string;
  name: string;
  // Not supported yet
  // item_total: number;
  // item_current: number;
  price: number;
  students: DependantStudent[];
  variant_id: string;
  variant_name: string;
  label: string;
};

export type PayableFee = {
  payer: PayableFeePayer;
  students: DependantStudent[];
  status: PayableFeeStatus;
  issue_date: string;
  due_date: string;
  total_paid: number | null; // number if status is partially_paid
  total_payment: number;
  items: FeeItem[];
  invoice_link?: string;
};

const getPayableFees = ({
  relation_ids,
  company_ids,
  ...rest
}: GetPayableFeesParams): Promise<PagedResponse<PayableFee>> => {
  const params = removeObjectUndefinedNullValues({
    relation_ids: relation_ids?.join(','),
    company_ids: company_ids?.join(','),
    ...rest,
  });
  return api.get(`${INVOICES_URL}/invoice/for-school`, { params });
};

export const GET_PAYABLE_FEES = `${INVOICES_URL}/GET_PAYABLE_FEES`;

export const useGetPayableFeesQuery = (
  initialParams: GetPayableFeesParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<PayableFee>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<PayableFee>, ApiError>(
    [GET_PAYABLE_FEES, params],
    ({ pageParam }) =>
      getPayableFees({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};
