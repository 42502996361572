import { Box, Stack } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { allowRenderSchoolPaymentsSettings } from '../../../helpers/renderRoutesByPermissions';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolIntegrationsXero } from './SchoolIntegrationsXero';

export const SchoolPayments: FC = () => {
  const { permissions, schoolId = '' } = useAuth();

  if (!allowRenderSchoolPaymentsSettings(permissions)) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="school-sections-Payments-Integrations" />
      </Box>

      <Stack gap={2}>
        <SchoolIntegrationsXero schoolId={schoolId} />
      </Stack>

      <Outlet />
    </MainLayout>
  );
};
