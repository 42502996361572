import { useMutation, useQuery } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import * as api from './requests';

const INTEGRATION_URL = '/integration';
const REDIRECT_URL = `${window.location.origin}/settings/xero-auth`;

export type XeroTenant = {
  id: string;
  name: string;
};

export type XeroAuthParams = {
  schoolId: string;
};

export enum XeroAuthState {
  Init = 'init', // not authenticated
  Auth = 'auth', // authenticating at this moment
  Expired = 'expired', // needs reauthentication
  Ready = 'api_ready', // authenticated
}

export type SetupXeroAuthResponse = {
  redirect_uri?: string;
  xero_enabled: boolean;
  state: XeroAuthState;
  has_previously_created_products: boolean;
  connected_xero_tenants?: XeroTenant[];
};

const getXeroAuthStatus = async (params: XeroAuthParams): Promise<SetupXeroAuthResponse> => {
  return await api.get(
    `${INTEGRATION_URL}/xero/setup?school_id=${params.schoolId}&redirect_uri=${encodeURIComponent(
      REDIRECT_URL,
    )}`,
  );
};

type CheckXeroAuthResponse = {
  xero_enabled: boolean;
  state: XeroAuthState;
  has_previously_created_products: boolean;
};

//TODO This is a temporary endpoint that is to be deleted once products can be used without xero
const checkXeroAuth = async (params: XeroAuthParams): Promise<CheckXeroAuthResponse> => {
  return await api.get(
    `${INTEGRATION_URL}/xero/check?school_id=${params.schoolId}&redirect_uri=${encodeURIComponent(
      REDIRECT_URL,
    )}`,
  );
};

export type CompleteXeroAuthParams = {
  schoolId: string;
  code: string;
  scope: string;
  state: string;
  session_state: string;
};

const completeXeroAuth = async (params: CompleteXeroAuthParams): Promise<any> => {
  return await api.get(
    `${INTEGRATION_URL}/xero/setup?school_id=${params.schoolId}&redirect_uri=${encodeURIComponent(
      REDIRECT_URL,
    )}&code=${params.code}&scope=${encodeURIComponent(params.scope)}&state=${
      params.state
    }&session_state=${params.session_state}`,
  );
};

export const CHECK_XERO_AUTH_QUERY = `${INTEGRATION_URL}/CHECK_XERO_AUTH_QUERY`;

export const useGetXeroAuthStatusQuery = (
  params: XeroAuthParams,
  options?: RQUseQueryOptions<SetupXeroAuthResponse>,
) => {
  return useQuery<SetupXeroAuthResponse, ApiError>(
    [CHECK_XERO_AUTH_QUERY, params],
    () => getXeroAuthStatus(params),
    {
      ...options,
    },
  );
};

const CHECK_XERO_AUTH_FOR_PRODUCT_QUERY = `${INTEGRATION_URL}/CHECK_XERO_AUTH_FOR_PRODUCT_QUERY`;

//TODO This is a temporary endpoint that is to be deleted once products can be used without xero
export const useCheckXeroAuthQuery = (
  params: XeroAuthParams,
  options?: RQUseQueryOptions<CheckXeroAuthResponse>,
) => {
  return useQuery<SetupXeroAuthResponse, ApiError>(
    [CHECK_XERO_AUTH_FOR_PRODUCT_QUERY, params],
    () => checkXeroAuth(params),
    {
      ...options,
    },
  );
};

export const useSendXeroAuthToken = (
  options?: RQUseMutationOptions<any, CompleteXeroAuthParams>,
): RQUseMutationResult<any, CompleteXeroAuthParams> => {
  return useMutation(completeXeroAuth, {
    ...options,
  });
};
