import {
  PaymentFrequency,
  PaymentFrequencyType,
  ProductSaveType,
  SchoolYear,
  XeroAccount,
} from '@schooly/api';
import { PRODUCTS_YEAR_ID_PARAM } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, setDate, setDay, setDayOfYear } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import useSchoolYears from '../../../hooks/useSchoolYears';

export const renderAccountName = (a: XeroAccount) =>
  `${a.xero_tenant_name ? `${a.xero_tenant_name} ` : ''}${a.name}`;
export const renderAccountTax = (a: XeroAccount) => `${a.tax_amount_percent}%`;
export const useRenderFrequencyDate = () => {
  const { $t } = useIntl();

  return (f: PaymentFrequency) => {
    const now = new Date();

    switch (f.frequency_type) {
      case PaymentFrequencyType.Weekly:
        return $t(
          { id: 'products-Frequency-Every' },
          { day: format(setDay(now, f.extra_data.day), 'EEE') },
        );
      case PaymentFrequencyType.Monthly:
        return $t(
          { id: 'products-Frequency-OfEveryMonth' },
          { day: format(setDate(now, f.extra_data.day), 'do') },
        );
      case PaymentFrequencyType.Annually: {
        const date = setDayOfYear(now, f.extra_data.day);
        return format(date, 'MMM d');
      }
      case PaymentFrequencyType.Termly:
      case PaymentFrequencyType.Biannually: {
        return f.extra_data.days.map((day) => format(setDayOfYear(now, day), 'MMM d')).join(', ');
      }
      default:
        return '';
    }
  };
};

export const getTypesByYears = <
  T extends Pick<ProductSaveType, 'active_from' | 'active_to' | 'year_id'>,
>(
  types: T[],
  years: SchoolYear[],
) => {
  const yearIntervals = years.map((year) => ({
    yearId: year.id,
    start: newDateTimezoneOffset(year.start).getTime(),
    end: newDateTimezoneOffset(year.end).getTime(),
  }));

  return types.reduce((typesByYears: { [year: string]: T[] }, type) => {
    if (type.active_from && type.active_to) {
      yearIntervals.forEach((interval) => {
        const typeStartDate = newDateTimezoneOffset(type.active_from).getTime();
        const typeEndDate = newDateTimezoneOffset(type.active_to).getTime();

        if (typeStartDate <= interval.end && typeEndDate >= interval.start) {
          const yearKey = interval.yearId;
          if (!typesByYears[yearKey]) {
            typesByYears[yearKey] = [];
          }
          typesByYears[yearKey].push(type);
        }
      });
    } else if (type.year_id) {
      const yearKey = type.year_id;
      if (!typesByYears[yearKey]) {
        typesByYears[yearKey] = [];
      }
      typesByYears[yearKey].push(type);
    }
    return typesByYears;
  }, {});
};

export const useSchoolYearsInProduct = (canShowYearInSelect: (year?: SchoolYear) => boolean) => {
  const { nextSchoolYear, defaultValidity, schoolYears } = useSchoolYears();
  const [params] = useSearchParams();

  const yearsForSelect = useMemo(
    () => schoolYears.filter(canShowYearInSelect),
    [canShowYearInSelect, schoolYears],
  );
  const defaultYear = canShowYearInSelect(defaultValidity) ? defaultValidity : undefined;
  //Based on TR-5885 next year should be pre-selected
  const defaultYearInSelect = nextSchoolYear ?? defaultYear ?? yearsForSelect[0];

  const paramsYearId = params.get(PRODUCTS_YEAR_ID_PARAM);
  const paramsSchoolYear = useMemo(
    () => yearsForSelect.find((y) => y.id === paramsYearId),
    [paramsYearId, yearsForSelect],
  );
  const initSelectedYear = paramsSchoolYear ?? defaultYearInSelect;

  const [selectedYear, setSelectedYear] = useState<SchoolYear | undefined>(initSelectedYear);

  useEffect(() => {
    if (paramsYearId && selectedYear?.id && paramsYearId !== selectedYear.id) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(PRODUCTS_YEAR_ID_PARAM, selectedYear.id);
      const newSearchParamsString = searchParams.toString();
      const newPath = `${window.location.pathname}?${newSearchParamsString}`;

      window.history.replaceState(null, '', newPath);
    }
  }, [paramsYearId, selectedYear?.id]);

  return { selectedYear, setSelectedYear, defaultYear: defaultYearInSelect, yearsForSelect };
};
