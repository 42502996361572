import { IconButton, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import {
  GetSchoolPaymentFrequenciesResponse,
  StudentForCompany,
  StudentForCompanyRelation,
  StudentProduct,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { CURRENCY_SYMBOLS, CurrencyCode } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  DropdownIcon,
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  randomInt,
  theme,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';

interface IHoverProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  sx: SxProps<Theme>;
}

interface CompanyPreviewStudentRowProps {
  student: StudentForCompany;
  frequencyData?: GetSchoolPaymentFrequenciesResponse;
  onEdit?: () => void;
  currency?: CurrencyCode;
}

export const CompanyPreviewStudentRow: FC<CompanyPreviewStudentRowProps> = ({
  student,
  frequencyData,
  onEdit,
  currency,
}) => {
  const [isExpanded, expand, collapse] = useFlag();
  const [rowHovered, setRowHovered] = useState(false);

  const hoverProps: IHoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: (theme: Theme) => ({
      ...(rowHovered && {
        backgroundColor: theme.palette.background.default,
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      }),
    }),
  };

  const expandedRowView = isExpanded || student.products.length === 1;
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';

  return expandedRowView ? (
    <>
      {student.products.map((product, index) => {
        return (
          <CompanyPreviewStudentExpandedRow
            key={product.id}
            action={collapse}
            hoverProps={hoverProps}
            rowHovered={rowHovered}
            rowIndex={index}
            product={product}
            student={student}
            frequencyData={frequencyData}
            onEdit={onEdit}
            currencySymbol={currencySymbol}
          />
        );
      })}
    </>
  ) : (
    <CompanyPreviewStudentCollapsedRow
      action={expand}
      hoverProps={hoverProps}
      rowHovered={rowHovered}
      student={student}
      onEdit={onEdit}
    />
  );
};

interface CompanyPreviewStudentCollapsedRowProps {
  hoverProps: IHoverProps;
  rowHovered: boolean;
  onEdit?: () => void;
  student: StudentForCompany;
  action: () => void;
}

const CompanyPreviewStudentCollapsedRow: FC<CompanyPreviewStudentCollapsedRowProps> = ({
  hoverProps,
  rowHovered,
  student,
  action,
  onEdit,
}) => {
  const productNames = student.products?.map((product) => product.name).join(', ');
  const isOneRow = student.products.length < 2;

  return (
    <GridRowStyled
      height={44}
      onClick={isOneRow ? undefined : action}
      sx={{
        cursor: isOneRow ? undefined : 'pointer',
        '& .GridRowItem': {
          height: '100%',
        },
      }}
    >
      <GridRowItem noPadding {...hoverProps}>
        <CompanyPreviewStudentCell student={student.relation} />

        <GridRowCell minWidth={455}>
          <TypographyWithOverflowHint variant="h3" sx={{ color: 'inherit' }}>
            {productNames}
          </TypographyWithOverflowHint>
        </GridRowCell>

        <CompanyPreviewActionCell
          handleClick={action}
          rowHovered={rowHovered}
          isEmpty={student.products.length < 2}
        />

        <CompanyPreviewEditCell rowHovered={rowHovered} onEdit={onEdit} />
      </GridRowItem>
    </GridRowStyled>
  );
};

interface CompanyPreviewStudentExpandedRowProps extends CompanyPreviewStudentCollapsedRowProps {
  product: StudentProduct;
  frequencyData?: GetSchoolPaymentFrequenciesResponse;
  rowIndex: number;
  currencySymbol: string;
}

const CompanyPreviewStudentExpandedRow: FC<CompanyPreviewStudentExpandedRowProps> = ({
  hoverProps,
  rowHovered,
  student,
  product,
  frequencyData,
  action,
  onEdit,
  rowIndex,
  currencySymbol,
}) => {
  const { $t } = useIntl();

  const frequency = frequencyData?.find((f) => f.id === product.frequency_id);

  const isFirstRow = rowIndex === 0;
  const isOneRow = student.products.length <= 1;
  const isLastRow = rowIndex === student.products.length - 1;

  return (
    <GridRowStyled
      height={44}
      noBorder
      onClick={isOneRow ? undefined : action}
      sx={{
        cursor: isOneRow ? undefined : 'pointer',
        '& .GridRowItem': {
          height: '100%',
        },
        '&:not(:last-child)': {
          '.GridRowItem:not(:first-child)': {
            borderBottom: theme.mixins.borderValue(),
          },
          '.studentNameCell': {
            height: '100%',
            borderBottom: isLastRow ? theme.mixins.borderValue() : undefined,
          },
        },
      }}
    >
      <GridRowItem noPadding {...hoverProps}>
        <CompanyPreviewStudentCell student={student.relation} isEmpty={!isFirstRow} />

        <GridRowItem minWidth={190} sx={{ paddingLeft: 0 }}>
          <TypographyWithOverflowHint variant="h3" sx={{ color: 'inherit' }}>
            {product.name}
          </TypographyWithOverflowHint>
        </GridRowItem>

        <GridRowItem minWidth={75}>
          {frequency ? (
            <Typography>{$t({ id: `products-Frequency-${frequency.frequency_type}` })}</Typography>
          ) : (
            <Skeleton />
          )}
        </GridRowItem>

        <GridRowItem minWidth={75}>
          <Typography
            sx={{ textDecoration: product.discount_percent ? 'line-through' : undefined }}
          >
            {currencySymbol} {product.price}
          </Typography>
        </GridRowItem>

        <GridRowItem minWidth={40}>
          <Typography>{product.discount_percent ? `${product.discount_percent}%` : '–'}</Typography>
        </GridRowItem>

        <GridRowItem minWidth={75}>
          <Typography>
            {product.discounted_price && product.discount_percent
              ? `${currencySymbol} ${product.discounted_price}`
              : ''}
          </Typography>
        </GridRowItem>

        <CompanyPreviewActionCell
          handleClick={action}
          rowHovered={rowHovered}
          isExpanded
          isEmpty={student.products.length < 2 || !isFirstRow}
        />

        <CompanyPreviewEditCell rowHovered={rowHovered} isEmpty={!isFirstRow} onEdit={onEdit} />
      </GridRowItem>
    </GridRowStyled>
  );
};

interface CompanyPreviewStudentCellProps {
  student: StudentForCompanyRelation;
  isEmpty?: boolean;
}

const CompanyPreviewStudentCell: FC<CompanyPreviewStudentCellProps> = ({ student, isEmpty }) => {
  return (
    <GridRowName minWidth={0} className="studentNameCell" px={1} sx={{ display: 'flex' }}>
      {!isEmpty && (
        <Stack direction="row" alignItems="center" gap={1.25} maxWidth="100%">
          <AvatarAuth user={student} />

          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            noWrap
            component={Link}
            to={getRouteModalPathname('student', student)}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
            onClick={(e) => e.stopPropagation()}
          >
            {getUserFullName(student)}
          </TypographyWithOverflowHint>
        </Stack>
      )}
    </GridRowName>
  );
};

interface CompanyPreviewActionCellProps {
  handleClick: () => void;
  rowHovered: boolean;
  isExpanded?: boolean;
  isEmpty?: boolean;
}

const CompanyPreviewActionCell: FC<CompanyPreviewActionCellProps> = ({
  handleClick,
  rowHovered,
  isExpanded,
  isEmpty,
}) => {
  const onCellClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleClick();
  };

  return (
    <GridRowItem minWidth={35} onClick={!isEmpty ? (e) => onCellClick(e) : undefined}>
      {!isEmpty && (
        <IconButton
          inverse={!rowHovered}
          sx={{
            transform: isExpanded ? 'rotate(180deg)' : undefined,
          }}
        >
          <DropdownIcon />
        </IconButton>
      )}
    </GridRowItem>
  );
};

interface CompanyPreviewEditCellProps {
  rowHovered: boolean;
  isEmpty?: boolean;
  onEdit?: () => void;
}

const CompanyPreviewEditCell: FC<CompanyPreviewEditCellProps> = ({
  rowHovered,
  isEmpty,
  onEdit,
}) => {
  return (
    <GridRowItem minWidth={35}>
      {!isEmpty && !!onEdit && (
        <IconButton onClick={onEdit} sx={{ visibility: rowHovered ? 'visible' : 'hidden' }}>
          <EditIcon />
        </IconButton>
      )}
    </GridRowItem>
  );
};

interface CompanyPreviewStudentListSkeletonProps {
  amount?: number;
}

export const CompanyPreviewStudentListSkeleton: FC<CompanyPreviewStudentListSkeletonProps> = ({
  amount = 5,
}) => {
  return (
    <>
      {[...new Array(amount)].map(() => (
        <GridRowStyled height={44} sx={{ pointerEvents: 'none' }}>
          <GridRowItem gap={1}>
            <GridRowName minWidth={0}>
              <Skeleton width={`${randomInt(50, 100)}%`} />
            </GridRowName>
            <GridRowCell minWidth={460}>
              <Skeleton width={`${randomInt(50, 100)}%`} />
            </GridRowCell>
            <GridRowCell minWidth={20}>
              <Skeleton />
            </GridRowCell>
            <GridRowCell minWidth={20}>
              <Skeleton />
            </GridRowCell>
          </GridRowItem>
        </GridRowStyled>
      ))}
    </>
  );
};
