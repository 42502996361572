import { useSendXeroAuthToken } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { Loading } from '@schooly/style';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SchoolXeroAuth: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId = '' } = useAuth();
  const [searchParams] = useSearchParams();
  const sendXeroAuthData = useSendXeroAuthToken();
  const navigate = useNavigate();
  const { showNotification } = useNotifications();

  useEffect(() => {
    const redirectBack = () => {
      navigate('/settings/payments');
    };

    sendXeroAuthData.mutate(
      {
        schoolId,
        scope: searchParams.get('scope') || '',
        state: searchParams.get('state') || '',
        code: searchParams.get('code') || '',
        session_state: searchParams.get('session_state') || '',
      },
      {
        onError: () => {
          showNotification({
            message: formatMessage({ id: 'products-xeroAuthError' }),
            type: 'error',
          });
          redirectBack();
        },
        onSuccess: () => {
          showNotification({ message: formatMessage({ id: 'products-xeroAuthSuccess' }) });
          redirectBack();
        },
      },
    );
    // Don't need any deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};
