import {
  Button,
  ClickAwayListener,
  Grow,
  Icon,
  Popper,
  PopperProps,
  Stack,
  Typography,
} from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { DoneIcon, WorldIcon } from '@schooly/style';
import {
  saveSelectedLanguage,
  SupportedLangCode,
  useRequestUserLanguage,
} from '@schooly/utils/translations';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

const LANGS: SupportedLangCode[] = ['en', 'es', 'pt', 'ru'];

const LANG_LABELS: { [k in SupportedLangCode]: string } = {
  en: 'English',
  es: 'Español',
  pt: 'Português',
  ru: 'Русский',
};

export const LanguageSelect: FC<
  { variant?: 'button' | 'icon' } & Omit<PopperProps, 'open' | 'children'>
> = ({ variant = 'button', ...rest }) => {
  const [isOpened, open, close] = useFlag(false);
  const { ref: anchorRef } = useResizeDetector<HTMLDivElement | null>();
  const { locale } = useIntl();

  useRequestUserLanguage();

  const handleSelectLang = (v: SupportedLangCode) => () => {
    saveSelectedLanguage(v);
    window.location.reload();
  };

  const renderButton = () => {
    switch (variant) {
      case 'button':
        return (
          <Button
            onClick={open}
            size="small"
            variant="text"
            startIcon={<WorldIcon />}
            sx={(theme) => ({
              flexDirection: 'row',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              backgroundColor: isOpened ? `${theme.palette.primary.main} !important` : undefined,
              color: isOpened ? `${theme.palette.background.paper} !important` : undefined,
              border: `0 !important`,
              fontSize: `${15}px !important`,
              paddingLeft: `${theme.spacing(1)} !important`,
              paddingRight: `${theme.spacing(1)} !important`,
            })}
          >
            {locale.toUpperCase()}
          </Button>
        );

      case 'icon':
        return (
          <Stack
            onClick={open}
            sx={(theme) => ({
              width: theme.spacing(3.75),
              height: theme.spacing(3.75),
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: !isOpened
                ? theme.palette.background.paper
                : theme.palette.primary.main,
              transition: 'all .2s',
              cursor: 'pointer',
            })}
          >
            <Typography variant="h3" color={isOpened ? 'background.paper' : 'primary.main'}>
              {locale.toUpperCase()}
            </Typography>
          </Stack>
        );
    }
  };

  return (
    <ClickAwayListener onClickAway={close}>
      <div>
        <Stack ref={anchorRef} display="inline-block">
          {renderButton()}
        </Stack>
        {isOpened && (
          <Popper
            {...rest}
            placement={rest.placement || 'bottom-start'}
            anchorEl={anchorRef.current}
            sx={(theme) => ({
              zIndex: theme.zIndex.tooltip - 1,
            })}
            open
            transition
          >
            {({ TransitionProps }) => {
              return (
                <Grow {...TransitionProps}>
                  <Stack
                    sx={(theme) => ({
                      position: 'relative',
                      backgroundColor: 'white',
                      maxHeight: 300,
                      overflowY: 'scroll',
                      border: `1px solid ${theme.palette.common.light3}`,
                      borderRadius: theme.spacing(1),
                      width: 140,
                      my: 0.5,
                      ...theme.mixins.dropdownShadow(),
                    })}
                  >
                    <Stack>
                      {LANGS.map((label) => {
                        const isSelected = label === locale;

                        return (
                          <Stack
                            key={label}
                            onClick={handleSelectLang(label)}
                            sx={(theme) => ({
                              px: 1,
                              py: 0.75,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: theme.palette.background.default,
                              },
                            })}
                          >
                            <Typography
                              variant="h3"
                              color={isSelected ? 'primary.main' : 'text.primary'}
                            >
                              {LANG_LABELS[label]}
                            </Typography>
                            {isSelected && (
                              <Icon
                                sx={{
                                  visibility: isSelected ? 'visible' : 'hidden',
                                }}
                              >
                                <DoneIcon />
                              </Icon>
                            )}
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </Grow>
              );
            }}
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  );
};
