import { IconButton } from '@mui/material';
import { GroupWithMembers, useExportConductForGroup } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { downloadFile } from '../../../../utils/downloadFile';

type GroupPreviewModalConductExportProps = {
  group?: GroupWithMembers;
};

export const GroupPreviewModalConductExport: FC<GroupPreviewModalConductExportProps> = ({
  group,
}) => {
  const { showError } = useNotifications();

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);
  const mutation = useExportConductForGroup();

  const handleExportClick = useCallback(() => {
    if (!group) return;

    mutation.mutate(
      {
        group_id: group.id,
        date:
          group.validity.date_from && group.validity.date_to
            ? [group.validity.date_from, group.validity.date_to]
            : undefined,
      },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${group.name}.conduct.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [mutation, group, showError]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  return (
    <IconButton
      disabled={!group}
      sx={{ color: mutation.isLoading || isSuccess ? 'primary.main' : 'text.primary' }}
      inverse
      onClick={mutation.isLoading ? undefined : handleExportClick}
    >
      {(() => {
        if (mutation.isLoading) {
          return <Spin />;
        }

        if (isSuccess) {
          return <CheckIcon />;
        }

        return <DownloadIcon />;
      })()}
    </IconButton>
  );
};
