import { Box, Button, Stack, Typography } from '@mui/material';
import { Counter } from '@schooly/style';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

interface HeaderProps {
  pageTitleTextId?: string;
  pageTitle?: React.ReactNode;
  pageTitleCounter?: number;
  buttonText?: string;
  buttonTextId?: string;
  buttonIcon?: React.ReactNode;
  buttonCounter?: number;
  buttonDisabled?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  showActionButton?: boolean;
}

export const PageHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
  pageTitleTextId,
  pageTitle,
  pageTitleCounter,
  buttonText,
  buttonTextId,
  buttonIcon,
  buttonCounter,
  buttonDisabled,
  showActionButton,
  onButtonClick,
  children,
}) => {
  return (
    <Stack
      data-cy={'page-header'}
      sx={(theme) => ({
        justifyContent: 'space-between',
        flexDirection: 'row',
        columnGap: 3,
        [theme.breakpoints.down('md')]: {
          rowGap: 1.5,
          flexWrap: 'wrap',
        },
      })}
    >
      <Typography
        data-cy={'page-header-title'}
        variant="subtitle1"
        sx={(theme) => ({
          minWidth: 220,
          [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
          },
        })}
      >
        {pageTitle || <FormattedMessage id={pageTitleTextId} />}
        {typeof pageTitleCounter !== 'undefined' && (
          <Counter sx={{ verticalAlign: 'middle' }} data-cy={'page-header-title-counter'}>
            {pageTitleCounter}
          </Counter>
        )}
      </Typography>
      <Stack
        sx={(theme) => ({
          flex: 1,
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            flexBasis: '100%',
            order: 3,
          },
        })}
      >
        {children}
      </Stack>
      <Box
        sx={(theme) => ({
          textAlign: 'right',
          minWidth: 220,
          [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
          },
        })}
      >
        {showActionButton && (
          <Button
            onClick={onButtonClick}
            startIcon={buttonIcon}
            disabled={buttonDisabled}
            data-cy={'page-header-action'}
          >
            {buttonText || <FormattedMessage id={buttonTextId} />}
            {typeof buttonCounter === 'number' && (
              <Counter
                sx={(theme) => ({
                  display: 'inline-block',
                  lineHeight: theme.spacing(1.75),
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.success.main,
                  fontSize: theme.spacing(1.25),
                })}
              >
                {buttonCounter}
              </Counter>
            )}
          </Button>
        )}
      </Box>
    </Stack>
  );
};
