import {
  ConductConnotation,
  ConductVisibility,
  LanguageAreas,
  LanguageLevel,
  MessageStatus,
  ReportStatuses,
  UserRoleAccessTypes,
  UserRoleCategories,
  UserRolePermissions,
} from '@schooly/api';
import { $enum } from 'ts-enum-util';

import { AssessmentStatuses } from './assessmentStatuses';
import { AttendanceCodeSchoolPresence } from './attendance';
import { Countries } from './countries';
import { countriesWithAreaCodes } from './countriesWithAreaCodes';
import { CustomFieldBooleanValues } from './customFields';
import { EventsInvite } from './events';
import { Languages } from './languages';
import { Nationalities } from './nationalities';
import { Genders, RelationsToChild, Titles } from './people';
import { SchoolEducationSystem, SchoolPresetsLanguage, SchoolUserRole } from './school';

export const getLabelIdForOption = ({ optionsKey, key }: { optionsKey?: string; key: string }) => {
  return `options-${optionsKey}-${key.replace(/ /g, '')}`;
};

export const convertEnumToSelectOptions = (
  e: { [id: number]: string | number },
  optionsKey?: string,
) =>
  $enum(e).map((value, key: string) => ({
    value,
    label: key as string,
    labelTextId: getLabelIdForOption({ optionsKey, key }),
  }));

export const EDUCATION_SYSTEM_OPTIONS = convertEnumToSelectOptions(
  SchoolEducationSystem,
  'education-system',
);

export const SCHOOL_PRESETS_LANGUAGE_OPTIONS = convertEnumToSelectOptions(
  SchoolPresetsLanguage,
  'school-presets-language',
);

export const ASSESSMENT_STATUSES_OPTIONS = convertEnumToSelectOptions(
  AssessmentStatuses,
  'assessment-status',
);

export const REPORT_STATUSES_OPTIONS = convertEnumToSelectOptions(ReportStatuses, 'report-status');

export const COUNTRY_OPTIONS = convertEnumToSelectOptions(Countries, 'country');

export const GENDER_OPTIONS = convertEnumToSelectOptions(Genders, 'gender').sort((a, b) =>
  a.label.localeCompare(b.label),
);

export const NATIONALITY_OPTIONS = convertEnumToSelectOptions(Nationalities, 'nationality');

export const MESSAGE_STATUS_OPTIONS = convertEnumToSelectOptions(MessageStatus, 'message');

export const LANGUAGE_OPTIONS = convertEnumToSelectOptions(Languages, 'language');

export const RECIPIENT_TYPE_OPTIONS = convertEnumToSelectOptions(
  SchoolUserRole,
  'recipient',
).filter((option) =>
  [SchoolUserRole.Parent, SchoolUserRole.Staff].includes(option.value as SchoolUserRole),
);

export const TITLE_OPTIONS = convertEnumToSelectOptions(Titles, 'title');
export const AREA_CODE_OPTIONS = countriesWithAreaCodes.map(({ code, name }) => {
  const label = `${name} (${code})`;
  return {
    value: code,
    label,
    labelTextId: `options-area-code-${name.replace(/ /g, '')}`,
  };
});

export const CONDUCT_TYPE_OPTIONS = $enum(ConductConnotation).map((value, key) => ({
  value,
  labelTextId: `conduct-Type-${(key as string).toLowerCase()}`,
}));

export const CONDUCT_STATUS_OPTIONS = $enum(ConductVisibility)
  .map((value, key) => {
    return {
      value,
      labelTextId: `conduct-Status-${(key as string).toLowerCase()}`,
    };
  })
  // At design first will be Published
  .reverse();

export const USER_ROLE_PERMISSION_OPTIONS = $enum(UserRolePermissions)
  .map((value, key) => {
    return {
      value,
      labelTextId: `userRoles-${key}`,
      key,
    };
  })
  .sort((a, b) => a.key.localeCompare(b.key));

export const USER_ROLE_CATEGORY_OPTIONS = $enum(UserRoleCategories).map((_, key) => ({
  category: key,
  labelTextId: `userRoles-${key}`,
}));

export const USER_ROLE_TYPE_OPTIONS = $enum(UserRoleAccessTypes).map((_, key) => ({
  type: key,
  labelTextId: `userRoles-${key}`,
}));

export const CUSTOM_FIELDS_BOOLEAN_OPTIONS = $enum(CustomFieldBooleanValues)
  .map((value, key) => ({
    booleanValue: `${!!value}`,
    labelTextId: key.toLowerCase(),
    value,
  }))
  .reverse();

export const RELATIONSHIP_TYPE_OPTIONS = $enum(RelationsToChild).map((value, key) => ({
  labelTextId: `relationship-Type-${key}`,
  value,
}));

export const EVENT_INVITE_OPTIONS = $enum(EventsInvite).map((value, key) => ({
  labelTextId: `events-${key}`,
  value,
}));

export const ATTENDANCE_CODE_SCHOOl_PRESENCE_OPTIONS = $enum(AttendanceCodeSchoolPresence)
  .map((value, key) => ({
    labelTextId: `attendance-${key}`,
    value,
  }))
  .reverse();

const LANGUAGE_LEVEL_OPTIONS = $enum(LanguageLevel).map((value, key) => ({
  labelTextId: `applications-Language-${key}`,
  value,
}));

export const LANGUAGE_AREAS_OPTIONS = $enum(LanguageAreas).map((value, key) => ({
  labelTextId: `applications-Language-${key}`,
  options: LANGUAGE_LEVEL_OPTIONS,
  name: value,
}));
