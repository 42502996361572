// import 'moment/dist/locale/nl';
import 'moment/dist/locale/es';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/ru';

// TODO: nl should be added when we have translations for it
// import nl from './nl';
import en from './lib/en';
import es from './lib/es';
import pt from './lib/pt';
import ru from './lib/ru';
import presetsEn from './school-presets/en/presets.json';
import presetsPt from './school-presets/pt/presets.json';

export const DEFAULT_LANGUAGE_CODE: SupportedLangCode = 'en';

export type SupportedLangCode = 'en' | 'es' | 'pt' | 'ru';

export const MESSAGES_BY_LANG_CODE: { [k in SupportedLangCode]: Record<string, string> } = {
  en,
  es,
  pt,
  ru,
  // nl,
};

export const isSupportedLangCode = (v: string): v is SupportedLangCode => {
  return Object.keys(MESSAGES_BY_LANG_CODE).includes(v);
};

export const SCHOOL_PRESETS_BY_LANG_CODE = {
  en: presetsEn,
  pt: presetsPt,
};
