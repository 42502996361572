import { SchoolPropertyType } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CancelToken } from 'axios';
import moment from 'moment';
import { useState } from 'react';

import { DEFAULT_DATE_FORMAT } from '../constants';
import { MinimalComment } from './apiTypes/base';
import {
  ConductEntry,
  ConductEntryPatch,
  ConductEntryPatchRequest,
  ConductStat,
  ConductType,
  ConductTypeCreate,
  ConductTypeHandle,
  ConductTypeUpdate,
  ConductVisibility,
} from './apiTypes/endpoints/conduct';
import { IColumnSort } from './apiTypes/endpoints/people';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { FilterKeys, UserFilter } from './apiTypes/users';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';
import { removeObjectFalsyValues } from './utils/removeObjectFalsyValues';

const DEFAULT_PAGE_SIZE = 50;

const CONDUCT_URL = '/conduct';

export interface ConductEntriesRequest {
  schoolId: string;
  query?: string;
  filters?: Partial<UserFilter>;
  sort?: IColumnSort<keyof ConductEntry>[];
  pageSize?: number;
  pageNumber?: number;
  token?: CancelToken;
}

export interface ConductEntryCreateRequest {
  date: string;
  students: ConductEntryPatch[];
}

export const getConductDefaultSortField = (): IColumnSort<keyof ConductEntry> => ({
  columnTextId: 'student',
  direction: SORT_DIRECTION.ASC,
});

export const getConductDefaultSort = (firstSort?: keyof ConductEntry) => {
  const sort: IColumnSort<keyof ConductEntry>[] = [
    getConductDefaultSortField(), // student always first
    { columnTextId: 'date', direction: SORT_DIRECTION.DESC },
    { columnTextId: 'conduct_type', direction: SORT_DIRECTION.ASC },
  ];

  if (firstSort && firstSort !== 'student') {
    const index = sort.findIndex((item) => item.columnTextId === firstSort);

    if (index > 0) {
      const item = sort.splice(index, 1);
      sort.splice(1, 0, ...item);
    }
  }

  return sort;
};

export const getConductEntries = ({
  schoolId,
  query,
  filters,
  sort = getConductDefaultSort(),
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: ConductEntriesRequest): Promise<PagedResponse<ConductEntry>> => {
  const sortBy = getSortParams(
    sort.reduce<IColumnSort[]>((prev, item) => {
      switch (item.columnTextId) {
        case 'student':
          prev.push({ columnTextId: 'last_name', direction: item.direction });
          prev.push({ columnTextId: 'given_name', direction: item.direction });
          break;
        default:
          prev.push(item);
      }

      return prev;
    }, []),
  );
  const dateFrom = filters?.date?.[0] || moment().format(DEFAULT_DATE_FORMAT);
  const dateTo = filters?.date?.[1];
  const singleDate =
    filters?.date?.length === 1
      ? filters?.date?.[0] || moment().format(DEFAULT_DATE_FORMAT)
      : undefined;

  const params = removeObjectFalsyValues({
    single_date: singleDate,
    date_from: dateTo ? dateFrom : undefined,
    date_to: dateTo,
    conduct_type_ids: filters?.conduct_type?.join(','),
    student_ids: filters?.student?.join(','),
    group_ids: filters?.group?.join(','),
    age_group: filters?.age_group?.join(','),
    house: filters?.house?.join(','),
    conduct_entry_ids: filters?.conduct_entry_ids?.join(','),
    visibility: filters?.visibility?.length === 2 ? undefined : filters?.visibility?.[0],
  });

  return api.get(`${CONDUCT_URL}/entries/for-school/${schoolId}`, {
    params: {
      ...params,
      search_query: query || undefined,
      sort_by: sortBy,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
};

export const GET_CONDUCT_ENTRIES_QUERY = `${CONDUCT_URL}/GET_CONDUCT_ENTRIES_QUERY`;

export const CONDUCT_ENTRIES_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.ConductStatus,
  FilterKeys.ConductType,
  FilterKeys.Student,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Group,
] as const;

export const CONDUCT_ENTRIES_GROUP_BY_FILTER_KEYS = [FilterKeys.Student] as const;

export type GetConductEntriesQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.ConductStatus]?: ConductVisibility[];
  [FilterKeys.ConductType]?: string[];
  [FilterKeys.Student]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
};

export type GetConductEntriesQuerySort = {
  columnTextId: 'student' | 'date' | 'conduct_type';
  direction: SORT_DIRECTION;
};

const prepareGetConductEntriesQuerySort = (v?: GetConductEntriesQuerySort) => {
  const byConductTypeSort: IColumnSort<keyof ConductEntry> = {
    columnTextId: 'conduct_type',
    direction: SORT_DIRECTION.ASC,
  };

  const byDateSort: IColumnSort<keyof ConductEntry> = {
    columnTextId: 'date',
    direction: SORT_DIRECTION.DESC,
  };

  let defaultQuerySort: IColumnSort<keyof ConductEntry>[] = [
    { columnTextId: 'student', direction: SORT_DIRECTION.ASC },
  ];

  // if we have a sort by `conduct_type` or `date`, it should be first in `sort_by`
  switch (v?.columnTextId) {
    case 'conduct_type':
      defaultQuerySort = [byConductTypeSort, ...defaultQuerySort];
      break;
    case 'date':
      defaultQuerySort = [byDateSort, ...defaultQuerySort];
      break;
    default:
      defaultQuerySort = [...defaultQuerySort, byDateSort, byConductTypeSort];
  }

  return defaultQuerySort.map((sort) => (v && sort.columnTextId === v.columnTextId ? v : sort));
};

export const useGetConductEntriesQuery = (
  initialParams: Omit<ConductEntriesRequest, 'filters' | 'sort'> & {
    filters: GetConductEntriesQueryFilters;
    sort?: GetConductEntriesQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<ConductEntry>>,
) => {
  const [params, setParams] = useState(initialParams);
  const query = useInfiniteQuery<PagedResponse<ConductEntry>, ApiError>(
    [GET_CONDUCT_ENTRIES_QUERY, params],
    ({ pageParam }) =>
      getConductEntries({
        pageNumber: pageParam,
        ...params,
        sort: prepareGetConductEntriesQuerySort(params.sort),
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const getConductTypesForSchool = (schoolId: string): Promise<ConductType[]> => {
  return api.get(`${CONDUCT_URL}/types/for-school/${schoolId}`);
};

export const GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY = `${CONDUCT_URL}/GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY`;

export const useGetConductTypesForSchoolQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<ConductType[]>,
) => {
  const queryClient = useQueryClient();
  return useQuery<ConductType[], ApiError>(
    [GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY, schoolId],
    () => getConductTypesForSchool(schoolId),
    {
      ...options,
      onSuccess: (data) => {
        for (const conductType of data) {
          queryClient.setQueryData([GET_CONDUCT_TYPE_QUERY, conductType.id], conductType);
        }
      },
    },
  );
};

export const getConductType = (typeId: string): Promise<ConductType> => {
  return api.get(`${CONDUCT_URL}/type/${typeId}`);
};

export const GET_CONDUCT_TYPE_QUERY = `${CONDUCT_URL}/GET_CONDUCT_TYPE_QUERY`;

export const useGetConductTypeQuery = (id: string, options?: RQUseQueryOptions<ConductType>) => {
  return useQuery<ConductType, ApiError>([GET_CONDUCT_TYPE_QUERY, id], () => getConductType(id), {
    ...options,
  });
};

type CreateConductTypesParams = {
  schoolId: string;
  conductTypes: ConductTypeCreate[];
};

export function createConductTypes({
  schoolId,
  conductTypes,
}: CreateConductTypesParams): Promise<ConductType> {
  return api.post(`${CONDUCT_URL}/types/for-school/${schoolId}`, conductTypes);
}

export const useCreateConductTypesMutation = (
  options?: RQUseMutationOptions<ConductType, CreateConductTypesParams>,
): RQUseMutationResult<ConductType, CreateConductTypesParams> => {
  return useMutation(createConductTypes, options);
};

type UpdateConductTypeParams = {
  typeId: string;
  conductType: Partial<ConductTypeUpdate>;
};

export function updateConductType({
  typeId,
  conductType,
}: UpdateConductTypeParams): Promise<ConductType> {
  return api.patch(`${CONDUCT_URL}/type/${typeId}`, conductType);
}

export const useUpdateConductTypeMutation = (
  options?: RQUseMutationOptions<ConductType, UpdateConductTypeParams>,
): RQUseMutationResult<ConductType, UpdateConductTypeParams> => {
  return useMutation(updateConductType, options);
};

export function handleConductTypes({
  schoolId,
  conductTypes,
}: {
  schoolId: string;
  conductTypes: ConductTypeHandle[];
}): Promise<ConductType> {
  return api.post(`${CONDUCT_URL}/handle-conduct-types/${schoolId}`, {
    conduct_types: conductTypes,
  });
}

export function deleteConductType(typeId: string): Promise<any> {
  return api.remove(`${CONDUCT_URL}/type/${typeId}`);
}

export const useDeleteConductTypeMutation = (
  options?: RQUseMutationOptions<ConductType, string>,
): RQUseMutationResult<ConductType, string> => {
  return useMutation(deleteConductType, options);
};

export const createConductEntryForRelation = (
  conductTypeId: string,
  data: ConductEntryCreateRequest,
) => {
  return api.post(`${CONDUCT_URL}/entries/for-type/${conductTypeId}`, data);
};

export const updateConductEntry = (conductEntryId: string, data: ConductEntryPatchRequest) => {
  return api.patch(`${CONDUCT_URL}/entries/${conductEntryId}`, data);
};

export const deleteConductEntry = (conductEntryId: string) => {
  return api.remove(`${CONDUCT_URL}/entries/${conductEntryId}`);
};

export const useDeleteConductEntryMutation = (
  options?: RQUseMutationOptions<{ success: string }, string>,
): RQUseMutationResult<{ success: string }, string> => {
  return useMutation(deleteConductEntry, options);
};

type AddEntryCommentResponse = { success: string; comment_id: string };

export const addEntryComment = (
  entryId: string,
  comment: MinimalComment,
): Promise<AddEntryCommentResponse> => {
  return api.post(`${CONDUCT_URL}/comment/for-entry/${entryId}`, comment);
};

type AddEntryCommentMutationParams = {
  entryId: string;
  comment: MinimalComment;
};

export const useAddEntryCommentMutation = (
  options?: RQUseMutationOptions<AddEntryCommentResponse, AddEntryCommentMutationParams>,
): RQUseMutationResult<AddEntryCommentResponse, AddEntryCommentMutationParams> => {
  return useMutation(
    (params: AddEntryCommentMutationParams) => addEntryComment(params.entryId, params.comment),
    options,
  );
};

export const editEntryComment = (
  entryId: string,
  commentId: string,
  comment: Partial<MinimalComment>,
): Promise<AddEntryCommentResponse> => {
  return api.patch(`${CONDUCT_URL}/comment/${commentId}`, {
    conduct_entry_id: entryId,
    ...comment,
  });
};

type EditEntryCommentMutationParams = {
  entryId: string;
  commentId: string;
  comment: Partial<MinimalComment>;
};

export const useEditEntryCommentMutation = (
  options?: RQUseMutationOptions<AddEntryCommentResponse, EditEntryCommentMutationParams>,
): RQUseMutationResult<AddEntryCommentResponse, EditEntryCommentMutationParams> => {
  return useMutation(
    (params: EditEntryCommentMutationParams) =>
      editEntryComment(params.entryId, params.commentId, params.comment),
    options,
  );
};

export type ExportConductForRelationParams = {
  relationId: string;
  [FilterKeys.Date]?: string[];
};

export const exportConductForRelation = (
  reqParams: ExportConductForRelationParams,
): Promise<ArrayBuffer> => {
  return api.get(`/export${CONDUCT_URL}/for-relation/${reqParams.relationId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params: reqParams[FilterKeys.Date]?.length
      ? {
          date_from: reqParams[FilterKeys.Date][0],
          date_to: reqParams[FilterKeys.Date][1],
        }
      : undefined,
  });
};

export const useExportConductForRelation = (
  options?: RQUseMutationOptions<ArrayBuffer, ExportConductForRelationParams>,
): RQUseMutationResult<ArrayBuffer, ExportConductForRelationParams> => {
  return useMutation(exportConductForRelation, options);
};

export type ExportConductForGroupParams = {
  group_id: string;
  [FilterKeys.Date]?: string[];
};

export const exportConductForGroup = (
  reqParams: ExportConductForGroupParams,
): Promise<ArrayBuffer> => {
  return api.get(`/export${CONDUCT_URL}/for-group/${reqParams.group_id}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params: reqParams[FilterKeys.Date]?.length
      ? {
          date_from: reqParams[FilterKeys.Date][0],
          date_to: reqParams[FilterKeys.Date][1],
        }
      : undefined,
  });
};

export const useExportConductForGroup = (
  options?: RQUseMutationOptions<ArrayBuffer, ExportConductForGroupParams>,
): RQUseMutationResult<ArrayBuffer, ExportConductForGroupParams> => {
  return useMutation(exportConductForGroup, options);
};

export interface ConductStatsRequest {
  schoolId: string;
  conduct_type_id: string;
  school_property_type: SchoolPropertyType;
  date_from?: string;
  date_to?: string;
  group_ids?: string;
  student_ids?: string;
  age_group_ids?: string;
  house_ids?: string;
  visibility?: ConductVisibility;
}

export const getConductStats = ({
  params: { schoolId, ...params },
  token,
}: {
  params: ConductStatsRequest;
  token?: CancelToken;
}): Promise<ConductStat[]> => {
  return api.get(`${CONDUCT_URL}/stats/for-school/${schoolId}`, {
    params,
    cancelToken: token,
  });
};

export const GET_CONDUCT_STATS_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.AgeGroup,
  FilterKeys.Student,
  FilterKeys.House,
  FilterKeys.Group,
  FilterKeys.ConductStatus,
] as const;

export type GetConductStatsQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.Student]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.ConductStatus]?: ConductVisibility[];
};

type GetConductStatsQueryParams = {
  schoolId: string;
  conduct_type_id: string;
  school_property_type: SchoolPropertyType;
  filters?: GetConductStatsQueryFilters;
};

export const GET_CONDUCT_STATS_QUERY = `${CONDUCT_URL}/GET_CONDUCT_STATS_QUERY`;

export const useGetConductStatsQuery = (
  params: GetConductStatsQueryParams,
  options?: RQUseQueryOptions<ConductStat[]>,
) => {
  return useQuery<ConductStat[], ApiError>(
    [GET_CONDUCT_STATS_QUERY, params],
    () => {
      const { schoolId, conduct_type_id, school_property_type, filters } = params;

      const requestParams: ConductStatsRequest = {
        schoolId,
        conduct_type_id,
        school_property_type,
        date_from: filters?.date?.[0] ? filters.date[0] : undefined,
        date_to: filters?.date?.[1] ? filters.date[1] : undefined,
        group_ids: filters?.group?.length ? filters.group.join(',') : undefined,
        student_ids: filters?.student?.length ? filters.student.join(',') : undefined,
        age_group_ids: filters?.age_group?.length ? filters.age_group.join(',') : undefined,
        house_ids: filters?.house?.length ? filters.house.join(',') : undefined,
        visibility: filters?.visibility?.length
          ? filters.visibility.includes(ConductVisibility.PUBLISHED)
            ? ConductVisibility.PUBLISHED
            : ConductVisibility.NOT_PUBLISHED
          : undefined,
      };

      return getConductStats({ params: requestParams });
    },
    {
      ...options,
    },
  );
};
