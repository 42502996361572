import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import {
  Company,
  DefaultPayer,
  PayerType,
  StudentProduct,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { CURRENCY_SYMBOLS, CurrencyCode } from '@schooly/constants';
import {
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  PlusIcon,
  randomInt,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ProfileModalMode } from '../../../../context/profile/helpers';
import { EmptySvg } from './EmptyListSvg';

export interface StudentProductWithStudentId extends StudentProduct {
  studentId?: string;
}

export const FeesList: FC<{
  products: StudentProductWithStudentId[];
  payer?: DefaultPayer;
  company?: Company;
  renderUserName?: (id: string) => ReactNode;
  schoolId: string;
  onRowClick?: (id: StudentProductWithStudentId) => void;
  endIcon?: ReactNode;
  currency?: CurrencyCode;
}> = ({ schoolId, products, payer, company, renderUserName, onRowClick, endIcon, currency }) => {
  const { $t } = useIntl();
  const { data } = useGetSchoolPaymentFrequencies(schoolId);
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';
  const { requiredFees, optionalFees } = useMemo(() => {
    const requiredFees: StudentProductWithStudentId[] = [];
    const optionalFees: StudentProductWithStudentId[] = [];

    for (const p of products) {
      if (p.obligatory) {
        requiredFees.push(p);
        continue;
      }

      optionalFees.push(p);
    }

    return {
      requiredFees,
      optionalFees,
    };
  }, [products]);

  const renderName = useCallback(
    (product: StudentProductWithStudentId) => {
      if (renderUserName && product.studentId) return renderUserName(product.studentId);

      const name =
        product.payer_type === PayerType.Default && payer ? getUserFullName(payer) : company?.name;
      return <TypographyWithOverflowHint>{name}</TypographyWithOverflowHint>;
    },
    [company?.name, payer, renderUserName],
  );

  const renderProduct = (product: StudentProductWithStudentId) => {
    const frequency = data?.find((f) => f.id === product.frequency_id);
    return (
      <GridRowStyled
        key={product.id}
        height={44}
        onClick={() => onRowClick && onRowClick(product)}
        sx={{ cursor: onRowClick && 'pointer' }}
      >
        <GridRowItem gap={1}>
          <GridRowName minWidth={0} sx={{ display: 'flex' }}>
            <TypographyWithOverflowHint
              variant="h3"
              component={Link}
              to={`/settings/products/${product.id}`}
              sx={{ '&:hover': { textDecoration: 'underline' } }}
              onClick={(e) => e.stopPropagation()}
            >
              {product.type_name} {product.name}
            </TypographyWithOverflowHint>
          </GridRowName>
          <GridRowCell minWidth={200}>
            <TypographyWithOverflowHint
              component={Link}
              to={
                product.payer_type === PayerType.Company
                  ? `/companies/${company?.id}`
                  : {
                      pathname: `/parents/${payer?.id}`,
                      hash: ProfileModalMode.DependantsAndProducts,
                    }
              }
              color="text.primary"
              sx={{ '&:hover': { textDecoration: 'underline' } }}
              onClick={(e) => e.stopPropagation()}
            >
              {renderName(product)}
            </TypographyWithOverflowHint>
          </GridRowCell>
          <GridRowCell minWidth={120}>
            {frequency ? (
              <Typography>
                {$t({ id: `products-Frequency-${frequency.frequency_type}` })}
              </Typography>
            ) : (
              <Skeleton />
            )}
          </GridRowCell>
          <GridRowCell minWidth={60}>
            <Typography
              sx={{ textDecoration: product.discount_percent ? 'line-through' : undefined }}
            >
              {currencySymbol} {product.price}
            </Typography>
          </GridRowCell>
          <GridRowCell minWidth={40}>
            <Typography>
              {product.discount_percent ? `${product.discount_percent}%` : '–'}
            </Typography>
          </GridRowCell>
          <GridRowCell minWidth={60}>
            <Typography>
              {product.discounted_price && product.discount_percent
                ? `${currencySymbol} ${product.discounted_price}`
                : ''}
            </Typography>
          </GridRowCell>
          {endIcon && <GridRowCell minWidth={20}>{endIcon}</GridRowCell>}
        </GridRowItem>
      </GridRowStyled>
    );
  };

  return (
    <Stack>
      {!!requiredFees.length && (
        <>
          <Typography variant="h4">{$t({ id: 'profile-ProductsRequired' })}</Typography>
          <Stack>{requiredFees.map(renderProduct)}</Stack>
        </>
      )}
      {!!optionalFees.length && (
        <>
          <Typography variant="h4" mt={requiredFees.length ? 2 : 0}>
            {$t({ id: 'profile-ProductsOptional' })}
          </Typography>
          <Stack>{optionalFees.map(renderProduct)}</Stack>
        </>
      )}
    </Stack>
  );
};

export const FeesListSkeleton: FC = memo(
  () => {
    return (
      <Box>
        <Typography mb={1}>
          <Skeleton width={50} />
        </Typography>
        <Stack>
          {[...new Array(5)].map((_, i) => (
            <GridRowStyled height={44} sx={{ pointerEvents: 'none' }}>
              <GridRowItem gap={1}>
                <GridRowName minWidth={0}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowName>
                <GridRowCell minWidth={200}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={120}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={60}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={40}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={60}>
                  <Skeleton />
                </GridRowCell>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </Stack>
      </Box>
    );
  },
  () => true,
);

interface FeesListEmptyProps {
  onAdd?: () => void;
  messageId?: string;
}

export const FeesListEmpty: FC<FeesListEmptyProps> = ({
  onAdd,
  messageId = 'profile-ThereAreNoActiveProductAssignmentsForStudent',
}) => {
  const { $t } = useIntl();

  return (
    <Stack flex={1} justifyContent="center" alignItems="center" gap={2} p={2}>
      <EmptySvg />
      <Typography textAlign="center" variant="h3" color="text.primary" maxWidth={280}>
        {$t({ id: messageId })}
      </Typography>
      {onAdd && (
        <Button startIcon={<PlusIcon />} onClick={onAdd}>
          {$t({ id: 'profile-AddProducts' })}
        </Button>
      )}
    </Stack>
  );
};
