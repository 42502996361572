import { Button, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import {
  Product,
  ProductSave,
  ProductSaveType,
  ProductSaveVariant,
  ProductTrigger,
  SchoolYear,
  TaxType,
  useCheckProductNameUniqueMutation,
  useGetSchoolAccounts,
  useGetSchoolPaymentFrequencies,
  useGetSchoolQuery,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { PropertyTypeSelect, SelectContentSkeleton } from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  InformationIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalLarge,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { renderAccountTax, useSchoolYearsInProduct } from '../helpers';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import { AccountSelect } from './AccountSelect';
import { SchoolProductCreateModalVariants } from './SchoolProductCreateModalVariants';
import { TaxTypeSelect } from './TaxTypeSelect';

type SchoolProductCreateModalContentProps = {
  initialState?: ViewState;
  product?: Product;
  schoolId: string;
  onSave: (v: ProductSave) => void;
  onDelete?: () => void;
  onClose: () => void;
  isSaving: boolean;
  isDeleting: boolean;
};

type IntersectionId =
  | {
      ageGroupId: IntersectsAll;
      subjectId: string;
    }
  | { ageGroupId: string; subjectId: IntersectsAll }
  | {
      ageGroupId: string;
      subjectId: string;
    };

export type IntersectionIds = {
  halfDayIds: IntersectionId[];
  fullDayIds: IntersectionId[];
};

export enum ViewState {
  General = 'general',
  Variants = 'variants',
}

const defaultValues: ProductSave = {
  name: '',
  description: '',
  obligatory: false,
  account_id: '',
  unique_fee_selection: false,
  tax_type: TaxType.Inclusive,
  trigger: {
    trigger_type: ProductTrigger.RegistrationUpdate,
    extra_data: { status: '' },
  },
  types: [
    {
      name: 'Standard',
      variants: [
        {
          half_day: false,
          prices: [],
          subjects: [],
          age_groups: [],
        },
      ],
    },
  ],
};

export const SchoolProductCreateModalContent: FC<SchoolProductCreateModalContentProps> = ({
  initialState = ViewState.General,
  product,
  schoolId,
  onClose,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
}) => {
  const { $t } = useIntl();
  const [state, setState] = useState<ViewState>(initialState);
  const { data: accounts } = useGetSchoolAccounts(schoolId);
  const { data: frequencies } = useGetSchoolPaymentFrequencies(schoolId);
  const checkProductNameUnique = useCheckProductNameUniqueMutation();
  const { showError } = useNotifications();
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const { getConfirmation } = useConfirmationDialog();
  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) => Boolean(year && !isDateInPast(year.end)),
    [],
  );
  const { selectedYear, setSelectedYear, defaultYear, yearsForSelect } =
    useSchoolYearsInProduct(canShowYearInSelect);

  const defaultTypes = useMemo(() => {
    if (product?.types.length) {
      return product.types;
    }
    return defaultValues.types.map((t) => ({
      ...t,
      //Based on TR-5885 we only send year_id on product create
      year_id: selectedYear?.id,
    }));
  }, [product?.types, selectedYear?.id]);

  const form = useForm<ProductSave>({
    defaultValues: product
      ? {
          name: product.name,
          description: product.description,
          obligatory: product.obligatory,
          account_id: product.account.id,
          tax_type: product.tax_type,
          trigger: product.trigger,
          unique_fee_selection: product.unique_fee_selection,
          types: defaultTypes,
        }
      : { ...defaultValues, types: defaultTypes },
  });

  const name = form.watch('name');
  const account_id = form.watch('account_id');
  const obligatory = form.watch('obligatory');
  const trigger = form.watch('trigger');
  const taxType = form.watch('tax_type');
  const applicableUnique = form.watch('unique_fee_selection');

  const selectedAccount = accounts?.find((a) => a.id === account_id);

  const [typesIntersectionIds, setTypesIntersectionIds] = useState<IntersectionIds[]>([]);
  const findTypesApplicableIntersections = useCallback(() => {
    const { types, unique_fee_selection } = form.getValues();

    setTypesIntersectionIds(
      types.map((type) =>
        unique_fee_selection
          ? findProductTypesDuplicates(types)
          : findProductTypesDuplicates([type]),
      ),
    );
  }, [form]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [onClose, form.formState.isDirty, getConfirmation]);

  const handleSubmit = useCallback(
    async (v: ProductSave) => {
      switch (state) {
        case ViewState.General: {
          const nameChanged = !product?.id || product.name !== v.name;

          if (!nameChanged) {
            form.clearErrors();
            setState(ViewState.Variants);
            return;
          }

          checkProductNameUnique.mutate(
            {
              schoolId,
              name: v.name,
            },
            {
              onSuccess: ({ is_unique }) => {
                if (!is_unique) {
                  form.setError('name', {
                    type: 'validate',
                    message: $t({ id: 'products-ProductNameExists' }, { name: v.name }),
                  });
                  form.setFocus('name');
                  return;
                }

                form.clearErrors();
                setState(ViewState.Variants);
              },
              onError: showError,
            },
          );

          return;
        }

        case ViewState.Variants: {
          //TODO Based on TR-5885 user can only create types for next year
          //Once this is changed validation for types in different years should be discussed and updated
          if (
            typesIntersectionIds.some((int) => {
              const { fullDayIds, halfDayIds } = int;

              return !!fullDayIds.length || halfDayIds.length;
            })
          ) {
            showError({
              message: $t({
                id: applicableUnique
                  ? 'products-ApplicableStudentsMustBeUniqueWithinAllProductTypes'
                  : 'products-ApplicableStudentsMustBeUniqueWithinOneProductType',
              }),
            });

            return;
          }

          onSave(v);
          return;
        }

        default:
          return;
      }
    },
    [
      $t,
      applicableUnique,
      checkProductNameUnique,
      form,
      onSave,
      product?.id,
      product?.name,
      schoolId,
      showError,
      state,
      typesIntersectionIds,
    ],
  );

  if (!accounts || !frequencies)
    return (
      <ModalSmall open onClose={handleClose}>
        <Loading />
      </ModalSmall>
    );

  const actionsDisabled = isDeleting || checkProductNameUnique.isLoading || isSaving;

  const deleteButton = !!onDelete && (
    <Button
      variant="outlined"
      startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
      disabled={actionsDisabled}
      onClick={onDelete}
    >
      {$t({ id: 'action-Delete' })}
    </Button>
  );

  if (state === ViewState.General)
    return (
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader
              active
              title={
                <Controller
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <ModalHeaderInput
                        placeholder={$t({ id: 'products-ProductName' })}
                        autoFocus={!field.value}
                        error={!!fieldState.error}
                        helperText={getControllerErrorText(fieldState.error, undefined, $t)}
                        {...field}
                      />
                    );
                  }}
                />
              }
            >
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalContent active>
              <Stack gap={4}>
                <Stack gap={1}>
                  <Typography variant="h2">
                    {$t({ id: 'products-SelectCorrespondingCode' })}
                  </Typography>
                  <Stack flexDirection="row" gap={1}>
                    <Stack flex={1}>
                      <Controller
                        control={form.control}
                        name="account_id"
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                          <AccountSelect
                            onSelectAccountId={field.onChange}
                            schoolId={schoolId}
                            requiredLabel="required"
                            selectedAccountId={field.value}
                            placeholder={$t({ id: 'products-Account' })}
                            error={fieldState.error}
                          />
                        )}
                      />
                    </Stack>
                    {!!selectedAccount && (
                      <>
                        <Stack flex={1}>
                          <FormTextField
                            disabled
                            label={$t({ id: 'products-Tax' })}
                            value={renderAccountTax(selectedAccount)}
                            customIcon={
                              <Tooltip title={$t({ id: 'products-ThisTaxIsTied' })}>
                                <IconButton>
                                  <InformationIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          />
                        </Stack>
                        <Stack flex={1}>
                          <Controller
                            control={form.control}
                            name="tax_type"
                            render={({ field, fieldState }) => (
                              <TaxTypeSelect
                                value={field.value}
                                onSelect={field.onChange}
                                placeholder={$t({ id: 'products-AmountsAre' })}
                                error={fieldState.error}
                              />
                            )}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Stack>
                {!!selectedAccount && (
                  <>
                    <Stack flexDirection="row" gap={3} justifyContent="space-between">
                      <Typography variant="h2">
                        {$t({ id: 'products-IsThisProductRequired?' })}
                      </Typography>

                      <Controller
                        control={form.control}
                        name="obligatory"
                        render={({ field }) => {
                          return (
                            <Stack flexDirection="row" gap={1}>
                              <Typography
                                variant="h3"
                                color={field.value ? 'common.grey' : 'primary.main'}
                              >
                                {$t({ id: 'products-Product-Optional' })}
                              </Typography>
                              <Switch
                                checked={field.value}
                                onChange={() => field.onChange(!field.value)}
                                sx={{ '& .MuiSwitch-thumb': { bgcolor: 'primary.main' } }}
                              />
                              <Typography
                                variant="h3"
                                width={70}
                                color={field.value ? 'primary.main' : 'common.grey'}
                              >
                                {$t({ id: 'products-Product-Required' })}
                              </Typography>
                            </Stack>
                          );
                        }}
                      />
                    </Stack>
                    <Stack flexDirection="row" gap={3} justifyContent="space-between">
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <Typography variant="h2">
                          {$t({ id: 'products-IsProductTypeSelectionUnique' })}
                        </Typography>
                        <Tooltip title={$t({ id: 'products-ProductTypesMustBeUnique' })}>
                          <IconButton inverse>
                            <InformationIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Controller
                        control={form.control}
                        name="unique_fee_selection"
                        render={({ field }) => {
                          return (
                            <Stack flexDirection="row" gap={1}>
                              <Typography
                                variant="h3"
                                color={field.value ? 'common.grey' : 'primary.main'}
                              >
                                {$t({ id: 'no' })}
                              </Typography>
                              <Switch
                                checked={field.value}
                                onChange={() => field.onChange(!field.value)}
                                sx={{ '& .MuiSwitch-thumb': { bgcolor: 'primary.main' } }}
                              />
                              <Typography
                                variant="h3"
                                width={70}
                                color={field.value ? 'primary.main' : 'common.grey'}
                              >
                                {$t({ id: 'yes' })}
                              </Typography>
                            </Stack>
                          );
                        }}
                      />
                    </Stack>
                    <Stack gap={1}>
                      <Typography variant="h2">{$t({ id: 'products-WhatWillTrigger' })}</Typography>
                      <Stack flexDirection="row" gap={1}>
                        <Stack flex={1}>
                          <FormTextField
                            customIcon={
                              <Tooltip title={$t({ id: 'products-AtTheMoment' })}>
                                <IconButton>
                                  <InformationIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            disabled
                            label={$t({ id: 'products-Trigger' })}
                            value={$t({ id: 'products-Trigger-Registration' })}
                          />
                        </Stack>
                        <Stack flex={1}>
                          <Controller
                            name="trigger.extra_data.status"
                            control={form.control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                              <PropertyTypeSelect
                                schoolId={schoolId}
                                userRole={SchoolUserRole.Student}
                                propertyType={SchoolPropertyType.Status}
                                value={field.value}
                                hasError={!!fieldState.error}
                                errorMessage={getControllerErrorText(
                                  fieldState.error,
                                  undefined,
                                  $t,
                                )}
                                onChange={field.onChange}
                                label={$t({ id: 'schoolProperty-Status' })}
                                renderEmptyStub={() => <SelectContentSkeleton />}
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </ModalContent>
            <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
              {deleteButton}
              <Button
                endIcon={checkProductNameUnique.isLoading ? <Spin /> : <ArrowRightIcon />}
                disabled={actionsDisabled}
                type="submit"
              >
                {$t({ id: 'action-Next' })}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    );

  return (
    <ModalLarge open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <SchoolProductModalHeader
            name={name}
            obligatory={obligatory}
            taxType={taxType}
            statusName={propertiesMap.status.find((s) => s.id === trigger.extra_data.status)?.name}
            account={selectedAccount}
            onClose={handleClose}
          />

          <ModalContent display="flex" flexDirection="column">
            <SchoolProductCreateModalVariants
              onVariantUpdated={findTypesApplicableIntersections}
              typesIntersectionIds={typesIntersectionIds}
              frequencies={frequencies}
              form={form}
              schoolId={schoolId}
              currency={currentSchool?.currency}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              yearsForSelect={yearsForSelect}
              defaultYear={defaultYear}
            />
          </ModalContent>
          <ModalFooter active sx={{ justifyContent: 'space-between' }}>
            {product ? (
              deleteButton
            ) : (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                disabled={isSaving}
                onClick={() => setState(ViewState.General)}
              >
                {$t({ id: 'action-Back' })}
              </Button>
            )}
            <Button
              startIcon={isSaving ? <Spin /> : <CheckIcon />}
              disabled={isSaving}
              type="submit"
            >
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalLarge>
  );
};

export type IntersectsAll = 'All';
export const INTERSECTS_ALL: IntersectsAll = 'All';

const findProductTypesDuplicates = (v: ProductSaveType[]): IntersectionIds => {
  const fullDayVariants = v.map((v) => v.variants.filter((v) => !v.half_day)).flat();
  const halfDayVariants = v.map((v) => v.variants.filter((v) => v.half_day)).flat();

  const getNonUniqueIntersections = (v: ProductSaveVariant[]) => {
    const allCombinations: IntersectionId[] = [];

    for (const variant of v) {
      if (!variant.age_groups.length && !variant.subjects.length) continue;

      const variantAgeGroups = variant.age_groups.length ? variant.age_groups : [INTERSECTS_ALL];

      for (const ageGroupId of variantAgeGroups) {
        const variantSubjects = variant.subjects.length ? variant.subjects : [INTERSECTS_ALL];

        for (const subjectId of variantSubjects) {
          allCombinations.push({
            ageGroupId,
            subjectId,
          });
        }
      }
    }

    return allCombinations.reduce<IntersectionId[]>((acc, comb) => {
      if (
        allCombinations.filter(
          (exComb) => exComb.ageGroupId === comb.ageGroupId && exComb.subjectId === comb.subjectId,
        ).length > 1 ||
        (comb.ageGroupId === INTERSECTS_ALL &&
          allCombinations.filter((exComb) => exComb.subjectId === comb.subjectId).length > 1) ||
        (comb.subjectId === INTERSECTS_ALL &&
          allCombinations.filter(
            (exComb) =>
              exComb.ageGroupId === comb.ageGroupId || exComb.ageGroupId === INTERSECTS_ALL,
          ).length > 1)
      )
        return [...acc, comb];
      return acc;
    }, []);
  };

  return {
    halfDayIds: getNonUniqueIntersections(halfDayVariants),
    fullDayIds: getNonUniqueIntersections(fullDayVariants),
  };
};
