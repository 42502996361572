import { IconButton, Stack, Typography } from '@mui/material';
import { TaxType, XeroAccount } from '@schooly/api';
import { CrossIcon, EditIcon, ModalHeader, TypographyWithOverflowHint } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { renderAccountName, renderAccountTax } from './helpers';

type SchoolProductModalHeaderProps = {
  name: string;
  account?: XeroAccount;
  obligatory: boolean;
  taxType: TaxType;
  statusName?: string;
  onClose: () => void;
  onEdit?: () => void;
};
export const SchoolProductModalHeader: FC<SchoolProductModalHeaderProps> = ({
  name,
  account,
  obligatory,
  taxType,
  statusName,
  onClose,
  onEdit,
}) => {
  const { $t } = useIntl();
  return (
    <ModalHeader
      active
      sx={(theme) => ({ paddingBottom: theme.spacing(1.5) })}
      title={
        <Stack mt={-0.5}>
          <Typography variant="h2" color="primary.main">
            {name}
          </Typography>
          <Stack flexDirection="row" gap={5}>
            {account && (
              <TypographyWithOverflowHint>
                {[
                  renderAccountName(account),
                  renderAccountTax(account),
                  $t({
                    id: `products-Tax-${taxType}`,
                  }),
                ].join(', ')}
              </TypographyWithOverflowHint>
            )}
            <TypographyWithOverflowHint>
              {$t({
                id: obligatory ? 'products-Product-Required' : 'products-Product-Optional',
              })}
            </TypographyWithOverflowHint>
            <TypographyWithOverflowHint>
              {$t(
                {
                  id: 'products-Trigger-Status',
                },
                {
                  status: statusName,
                },
              )}
            </TypographyWithOverflowHint>
          </Stack>
        </Stack>
      }
    >
      <Stack flexDirection="row" gap={2}>
        {!!onEdit && (
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </ModalHeader>
  );
};
