import { Card, Icon, Skeleton, Stack, Typography } from '@mui/material';
import { useGetXeroAuthStatusQuery, XeroAuthState } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

type SchoolIntegrationsXeroProps = {
  schoolId: string;
};
export const SchoolIntegrationsXero: FC<SchoolIntegrationsXeroProps> = ({ schoolId }) => {
  const { showError } = useNotifications();

  const { data } = useGetXeroAuthStatusQuery(
    {
      schoolId,
    },
    { enabled: !!schoolId, refetchOnMount: 'always', onError: showError, retry: 0 },
  );

  const renderButton = () => {
    const skeleton = <Skeleton variant="rectangular" height={30} width={150} />;

    if (!data) return skeleton;

    const { has_previously_created_products, redirect_uri = '', xero_enabled, state } = data;

    const xeroNotAvailable =
      !xero_enabled || (has_previously_created_products && state === XeroAuthState.Init);

    if (xeroNotAvailable)
      return (
        <SimpleButton disabled>{formatMessage({ id: 'products-xeroNotAvailable' })}</SimpleButton>
      );

    return (
      <SimpleButton startIcon={<PlusIcon />} onClick={() => window.location.assign(redirect_uri)}>
        {formatMessage({ id: 'products-addXeroOrg' })}
      </SimpleButton>
    );
  };

  const { formatMessage } = useIntl();
  return (
    <Card>
      <Stack gap={3} p={2.5}>
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
          <Stack>
            <Typography variant="h2">
              {formatMessage({ id: 'products-pleaseAuthXeroTitle' })}
            </Typography>
          </Stack>
          {renderButton()}
        </Stack>

        {(data?.connected_xero_tenants || []).map((tenant) => (
          <Stack
            key={tenant.id}
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="space-between"
          >
            <Stack>
              <Typography variant="h3">{tenant.name}</Typography>
              <Typography>{formatMessage({ id: 'products-xeroAuthSuccess' })}</Typography>
            </Stack>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                height: theme.spacing(3.75),
                gap: theme.spacing(0.75),
                padding: theme.spacing(0, 1.25),
                borderRadius: theme.spacing(2.5),
                backgroundColor: theme.palette.success.superLight,
                border: `1px solid ${theme.palette.success.main}`,
              })}
            >
              <Icon sx={(theme) => ({ color: theme.palette.success.main })}>
                <CheckIcon />
              </Icon>
              <Typography color="success.main">
                {formatMessage({ id: 'products-xeroAuthenticated' })}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
