import { iso1A2Code } from '@rapideditor/country-coder';

import { DEFAULT_LANGUAGE_CODE, isSupportedLangCode, SupportedLangCode } from './constants';

export const SCHOOLY_USER_SELECTED_LANGUAGE_STORAGE_KEY = 'schooly-app@language';
const PT_SPEAKING_COUNTRIES = ['PT', 'BR', 'AO', 'MZ'];
const ES_SPEAKING_COUNTRIES = [
  'VE',
  'UY',
  'ES',
  'PE',
  'PY',
  'PA',
  'NI',
  'MX',
  'HN',
  'GT',
  'GQ',
  'SV',
  'EC',
  'DO',
  'CU',
  'CR',
  'CO',
  'CL',
  'BO',
  'AR',
];

const RU_SPEAKING_COUNTRIES = [
  'RU',
  'BY',
  'KZ',
  'KG',
  'UA',
  'MD',
  'AZ',
  'GE',
  'AM',
  'UZ',
  'TM',
  'TJ',
];
const getLanguageFromGeolocation = async (): Promise<SupportedLangCode> => {
  const getPosition = () =>
    new Promise<GeolocationPosition>((res, rej) => {
      window.navigator.geolocation.getCurrentPosition(res, rej);
    });

  try {
    const position = await getPosition();
    const country = iso1A2Code([position.coords.longitude, position.coords.latitude]);

    if (!country) throw new Error('Cannot parse coordinates');

    if (PT_SPEAKING_COUNTRIES.includes(country)) {
      return 'pt';
    }
    if (ES_SPEAKING_COUNTRIES.includes(country)) {
      return 'es';
    }
    if (RU_SPEAKING_COUNTRIES.includes(country)) {
      return 'ru';
    }

    return 'en';
  } catch {
    return 'en';
  }
};

export const setDefaultLanguageFromGeolocation = (currentLocale: string) => {
  const savedLanguage = localStorage.getItem(SCHOOLY_USER_SELECTED_LANGUAGE_STORAGE_KEY);
  if (savedLanguage) return;

  getLanguageFromGeolocation().then((code) => {
    saveSelectedLanguage(code);
    if (code !== currentLocale) {
      window.location.reload();
    }
  });
};

export function getClientLanguage(overrideLanguage?: string): SupportedLangCode {
  const savedLanguage = localStorage.getItem(SCHOOLY_USER_SELECTED_LANGUAGE_STORAGE_KEY);

  const navigator = window.navigator;
  const navigatorLanguage =
    (navigator.languages && navigator.languages[0]) || navigator.language || DEFAULT_LANGUAGE_CODE;

  const language = (savedLanguage || overrideLanguage || navigatorLanguage)
    .toLowerCase()
    .split(/[_-]+/)[0] as string;

  if (isSupportedLangCode(language)) {
    if (!savedLanguage && !!overrideLanguage) {
      saveSelectedLanguage(language);
    }

    return language;
  }

  return DEFAULT_LANGUAGE_CODE;
}

export const saveSelectedLanguage = (v: SupportedLangCode) => {
  localStorage.setItem(SCHOOLY_USER_SELECTED_LANGUAGE_STORAGE_KEY, v);
};

export const clearSelectedLanguage = (v: SupportedLangCode) => {
  localStorage.removeItem(SCHOOLY_USER_SELECTED_LANGUAGE_STORAGE_KEY);
};
